import React, { useEffect, useState } from "react";
import QuestionSet from "../TestPage/Questionset.jsx";
import "./Test.css";
import axios from "axios";
import Header from "../../components/Header/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Test = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const adminEmails = ["ad@b.c", "RashmiAdmin@123"];

  const [userDetails, setUserDetails] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [setSubmitted, setSetSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    height: "",
    weight: "",
    age: "",
    contactno: "",
    Activity: "",
    sex: "",
    Health: "",
  });
  const [ans, setAns] = useState({});
  const [video, setVideo] = useState(null);
  const [isAnsAdded, setAnsAdded] = useState(false);
  const email = localStorage.getItem("email");
  const [enduser, setEnduser] = useState(true);

  const url = window.location.pathname;

  const steps = [
    { step: 1, sets: 1, label1: t("labelS1"), label2: t("labelD1") },
    { step: 2, sets: 5, label1: t("labelS2"), label2: t("labelD2") },
    { step: 3, sets: 6, label1: t("labelS3"), label2: t("labelD3") },
    { step: 4, sets: 2, label1: t("labelS4"), label2: t("labelD4") },
    { step: 5, sets: 1, label1: t("labelS5"), label2: t("labelD5") },
  ];

  const totalSetsForCurrentStep = steps[currentStep - 1].sets;

  const questionSets = {
    1: [
      {
        header: t("header1"),
        fields: [
          {
            label: (
              <>
                {t("FullName")}
                <span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Full Name",
            type: "text",
            name: "fullName",
            placeholder: t("FullNamePlaceholder"),
            placeholder: t("FullNamePlaceholder"),
            mandatory: true,
          },
          {
            label: (
              <>
                {t("Height")}
                <span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Height",
            type: "number",
            name: "height",
            placeholder: t("HeightPlaceholder"),
            mandatory: true,
          },
          {
            label: (
              <>
                {t("Weight")}
                <span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Weight",
            type: "number",
            name: "weight",
            placeholder: t("weightPlaceholder"),
            mandatory: true,
          },
          {
            label: (
              <>
                {t("Age")}
                <span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "age",
            type: "number",
            name: "age",
            placeholder: t("AgePlaceholder"),
            mandatory: true,
          },
          {
            label: (
              <>
                {t("Contact No")}
                <span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Contact No.",
            type: "number",
            name: "contactno",
            placeholder: t("ContactNoPlaceholder"),
            mandatory: true,
          },
          {
            label: (
              <>
                {t("ActivityFactor")}
                <span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Activity Factor",
            type: "select",
            name: "Activity",
            options: [
              t("ActivityFactorOption1"),
              t("ActivityFactorOption2"),
              t("ActivityFactorOption3"),
              t("ActivityFactorOption4"),
            ],
            value:[ "1.2","1.375","1.55","1.725"],
            mandatory: true,
          },
          {
            label: (
              <>
                {t("Gender")}
                <span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Gender",
            type: "select",
            name: "sex",
            options: [
              t("Genderoption1"),
              t("Genderoption2"),
              t("Genderoption3"),
            ],
            value: ["Male", "Female", "Other"],
            mandatory: true,
          },
          {
            label: t("AnySpecificHealthCondition"),
            type: "text",
            name: "Health",
            placeholder: t("AnySpecificHealthConditionPlaceholder"),
            mandatory: false,
          },
          ...(adminEmails.includes(email)
            ? [
                {
                  label: t("Prakriti Category"),
                  labelText: "Prakriti",
                  type: "text",
                  name: "prakriti",
                  placeholder: t(""),
                  mandatory: true,
                },
                {
                  label: t("Vikruti Category"),
                  labelText: "Vikruti",
                  type: "text",
                  name: "vikruti",
                  placeholder: t(""),
                  mandatory: true,
                },
              ]
            : []),
        ],
      },
    ],
    2: [
      {
        header: t("PrakritiHeader"),
        fields: [
          {
            label: t("Performance"),
            type: "radio",
            name: "2a_performance",
            options: [
              t("PerformanceOption1"),
              t("PerformanceOption2"),
              t("PerformanceOption3"),
            ],
            mandatory: true,
          },
          {
            label: t("Memory"),
            type: "radio",
            name: "2b_memory",
            options: [
              t("MemoryOption1"),
              t("MemoryOption2"),
              t("MemoryOption3"),
            ],
            mandatory: true,
          },
        ],
      },
      {
        header: t("PrakritiHeader"),
        fields: [
          {
            label: t("SleepPattern"),
            type: "radio",
            name: "2c_sleep",
            options: [
              t("SleepPatternOption1"),
              t("SleepPatternOption2"),
              t("SleepPatternOption3"),
            ],
            mandatory: true,
          },
          {
            label: t("Climate"),
            type: "radio",
            name: "2d_climate",
            options: [
              t("ClimateOption1"),
              t("ClimateOption2"),
              t("ClimateOption3"),
            ],
            mandatory: true,
          },
        ],
      },
      {
        header: t("PrakritiHeader"),
        fields: [
          {
            label: t("Hunger"),
            type: "radio",
            name: "2e_hunger",
            options: [
              t("HungerOption1"),
              t("HungerOption2"),
              t("HungerOption3"),
            ],
            mandatory: true,
          },
          {
            label: t("BodyTemperature"),
            type: "radio",
            name: "2f_BodyTemperature",
            options: [
              t("BodyTemperatureOption1"),
              t("BodyTemperatureOption2"),
              t("BodyTemperatureOption3"),
            ],
            mandatory: true,
          },
        ],
      },
      {
        header: t("PrakritiHeader"),
        fields: [
          {
            label: t("Stamina"),
            type: "radio",
            name: "2g_Stamina",
            options: [
              t("StaminaOption1"),
              t("StaminaOption2"),
              t("StaminaOption3"),
            ],
            mandatory: true,
          },
          {
            label: t("BowelMovements"),
            type: "radio",
            name: "2h_Bowel",
            options: [
              t("BowelMovementsOption1"),
              t("BowelMovementsOption2"),
              t("BowelMovementsOption3"),
            ],
            mandatory: true,
          },
        ],
      },
      {
        header: t("PrakritiHeader"),
        fields: [
          {
            label: t("Reactionunderadversesituation"),
            type: "radio",
            name: "2i_Reaction",
            options: [
              t("ReactionunderadversesituationOption1"),
              t("ReactionunderadversesituationOption2"),
              t("ReactionunderadversesituationOption3"),
            ],
            mandatory: true,
          },
        ],
      },
    ],
    3: [
      {
        header: t("VikrutiHeader1"),
        description: t("VikrutiSymptomsDescription"),
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms1",
            type: "checkbox",
            options: [
              t("SymptomsOption1"),
              t("SymptomsOption2"),
              t("SymptomsOption3"),
              t("SymptomsOption4"),
            ],
            value: [
              "Lack of strength",
              "Desire for hot things (craving warmth)",
              "Bloating",
              "Emaciation (skinny/sudden weight loss)",
            ],
          },
          {
            name: "vikrutiSymptoms1.1",
            type: "checkbox",
            options: [
              t("SymptomsOption5"),
              t("SymptomsOption6"),
              t("SymptomsOption7"),
              t("SymptomsOption8"),
            ],
            value: [
              "Reduction in sleep duration",
              "Difficulty in breathing",
              "Constipation",
              "Over talkativeness",
            ],
          },
          {
            name: "vikrutiSymptoms1.2",
            type: "checkbox",
            options: [
              t("SymptomsOption9"),
              t("SymptomsOption10"),
              t("SymptomsOption11"),
            ],
            value: ["Depression, anxiety", "Body pain", "Roughness in skin"],
          },
        ],
      },
      {
        header: t("VikrutiHeader2"),
        description: t("VikrutiSymptomsDescription"),
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms2",
            type: "checkbox",
            options: [
              t("Symptoms2Option1"),
              t("Symptoms2Option2"),
              t("Symptoms2Option3"),
            ],
            value: [
              "Decreased activity (lethargy)",
              "Debility (physical weakness)",
              "Tiredness after speaking a few words",
            ],
          },
        ],
      },
      {
        header: t("VikrutiHeader3"),
        description: t("VikrutiSymptomsDescription"),
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms3.1",
            type: "checkbox",
            options: [
              t("Symptoms3Option1"),
              t("Symptoms3Option2"),
              t("Symptoms3Option3"),
              t("Symptoms3Option4"),
            ],
            value: [
              "Burning sensation",
              "Increased heat in the body",
              "Excessive hunger",
              "Sleeplessness",
            ],
          },
          {
            name: "vikrutiSymptoms3.2",
            type: "checkbox",
            options: [
              t("Symptoms3Option5"),
              t("Symptoms3Option6"),
              t("Symptoms3Option7"),
            ],
            value: [
              "Fainting and exhaustion",
              "Acidic belching (acidic burps)",
              "Reddish discoloration of body",
            ],
          },
          {
            name: "vikrutiSymptoms3.3",
            type: "checkbox",
            options: [
              t("Symptoms3Option8"),
              t("Symptoms3Option9"),
              t("Symptoms3Option10"),
            ],
            value: [
              "Excessive thirst",
              "Increased sweating",
              "Yellowish discoloration of stools, urine, skin and eye",
              "Yellowish discoloration of stools, urine, skin and eye",
            ],
          },
        ],
      },
      {
        header: t("VikrutiHeader4"),
        description: t("VikrutiSymptomsDescription"),
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms4",
            type: "checkbox",
            options: [
              t("Symptoms4Option1"),
              t("Symptoms4Option2"),
              t("Symptoms4Option3"),
              t("Symptoms4Option4"),
            ],
            value: [
              "Poor digestion",
              "Cold feet and hands",
              "Lack of warmth",
              "Dullness",
            ],
          },
        ],
      },
      {
        header: t("VikrutiHeader5"),
        description: t("VikrutiSymptomsDescription"),
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms5.1",
            type: "checkbox",
            options: [
              t("Symptoms5Option1"),
              t("Symptoms5Option2"),
              t("Symptoms5Option3"),
              t("Symptoms5Option4"),
            ],
            value: [
              "Feeling of heaviness in body part",
              "Lethargy laziness",
              "Aversion of food (strong dislike towards a particular food)",
              "Nausea",
            ],
          },
          {
            name: "vikrutiSymptoms5.2",
            type: "checkbox",
            options: [
              t("Symptoms5Option5"),
              t("Symptoms5Option6"),
              t("Symptoms5Option7"),
              t("Symptoms5Option8"),
            ],
            value: [
              "Heaviness in the chest",
              "Excessive fat",
              "Swelling in the body or parts of body",
               "Itching",
            ],
          },
          {
            name: "vikrutiSymptoms5.3",
            type: "checkbox",
            options: [
              t("Symptoms5Option9"),
              t("Symptoms5Option10"),
              t("Symptoms5Option11"),
              t("Symptoms5Option12"),
            ],
            value: [
              "Indigestion",
              "Excessive sleep",
              "Breathlessness",
              "Excessive salivation",
            ],
          },
        ],
      },
      {
        header: t("VikrutiHeader6"),
        description: t("VikrutiSymptomsDescription"),
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms6",
            type: "checkbox",
            options: [
              t("Symptoms6Option1"),
              t("Symptoms6Option2"),
              t("Symptoms6Option3"),
              t("Symptoms6Option4"),
            ],
            value: [
              "Dryness in the skin",
              "Lack of sleep",
              "Tiredness /weakness",
              "Frequent infection",
            ],
          },
        ],
      },
    ],
    4: [
      {
        header: t("InflammationHeader"),
        allowSkip: true,
        fields: [
          {
            name: "inflamation1",
            type: "checkbox",
            options: [
              t("InflammationOption1"),
              t("InflammationOption2"),
              t("InflammationOption3"),
              t("InflammationOption4"),
            ],
            value: [
              "Swelling in the body parts",
              "Lack of strength",
              "Low immunity",
              "Poor metabolism",
            ],
          },
          {
            name: "inflamation2",
            type: "checkbox",
            options: [
              t("InflammationOption5"),
              t("InflammationOption6"),
              t("InflammationOption7"),
              t("InflammationOption8"),
            ],
            value: [
              "Decreased sweating",
              "Tiredness",
              "Indigestion",
              "The body is producing dirty or unhealthy mucus",
            ],
          },
          {
            name: "inflamation3",
            type: "checkbox",
            options: [
              t("InflammationOption9"),
              t("InflammationOption10"),
              t("InflammationOption11"),
            ],
            value: [
              "Tastelessness",
              "Lethargy (lack of energy and enthusiasm)",
              "Thirst",
            ],
          },
        ],
      },
      {
        header: t("NonInflammatoryHeader"),
        allowSkip: true,
        fields: [
          {
            name: "noninflamation1",
            type: "checkbox",
            options: [
              t("NonInflammatoryOption1"),
              t("NonInflammatoryOption2"),
              t("NonInflammatoryOption3"),
              t("NonInflammatoryOption4"),
            ],
            value: [
              "Balanced digestion of food",
              "Free and effortless expulsion of stool",
              "Appreciate the taste of food",
              "Absence of tiredness",
            ],
            // mandatory: true,
          },
          {
            name: "noninflamation1.2",
            type: "checkbox",
            options: [
              t("NonInflammatoryOption5"),
              t("NonInflammatoryOption6"),
              t("NonInflammatoryOption7"),
            ],
            value: [
              "Feeling of lightness of the body",
              "Feeling energetic in body",
              "Absence of swelling and stiffness in the body",
            ],
            // mandatory: true,
          },
        ],
      },
    ],
    5: [{ last: "yes", fields: [{ dummy1: "" }] }],
  };

  //For setting the UserName
  // useEffect(() => {
  //   setFormData({ ...formData, []: userDetails })
  // },[userDetails])

  useEffect(() => {
    const isToken = url.includes("token");
    console.log("isToken", isToken);
    if (!isToken) {
      const fetchUser = async () => {
        try {
          const User = await axios.get(
            "https://aivotayur.com/api/loginDetails",
            {
              params: { email }, // Pass the email as a query parameter
            }
          );
          setUserDetails(User.data.LoginData);
          setFormData({
            fullName: User.data.LoginData.fullName || "",
            height: User.data.LoginData.height || "",
            weight: User.data.LoginData.weight || "",
            age: User.data.LoginData.age || "",
            contactno: User.data.LoginData.contactno || "",
            Activity: User.data.LoginData.Activity || "",
            sex: User.data.LoginData.sex || "",
            Health: User.data.LoginData.Health || "",
          });
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      };

      fetchUser();
    } else {
      const token = url.split("=")[1];
      console.log(token, url);
      const verifyToken = async () => {
        try {
          const res = await axios.get("https://aivotayur.com/api/token", {
            params: { token },
          });
          console.log("res of token", res);
          if (res.status !== 200) {
            navigate("/");
          }
        } catch (error) {
          console.log("error in token validation", error.response?.message);
          navigate("/");
        }
      };

      verifyToken();
    }
  }, []);

  useEffect(() => {
    console.log("'user details", userDetails);
  }, [userDetails]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAnsAdded(true);
    console.log(name, value, type, checked);

    if (type === "checkbox") {
      const values = formData[name] || [];
      setFormData({
        ...formData,
        [name]: checked
          ? [...values, value]
          : values.filter((val) => val !== value),
      });
    } else if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked ? value : prevData[name],
      }));
    } else if (type === "number") {
      // Prevent negative values
      if (value >= 0 || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    // console.log(document.querySelectorAll('input[type="checkbox"]'))
    if (
      Array.from(document.querySelectorAll('input[type="checkbox"]')).some(
        (checkbox) => checkbox.checked
      )
    )
      setAnsAdded(true);
    else setAnsAdded(false);
  }, [currentStep, currentSet]);

  const handleSubmitSet = () => {
    setAns((val) => ({ ...val, ...formData }));

    const currentQuestions =
      questionSets[currentStep]?.[currentSet - 1]?.fields || [];
    let isValid = true;

    currentQuestions.some((field) => {
      if (field.mandatory) {
        if (
          (field.type === "text" || field.type === "select") &&
          (!formData[field.name] || formData[field.name].trim() === "")
        ) {
          isValid = false;

          toast.error(`${field.labelText} is mandatory!`, {
            autoClose: 3000, // Closes the toast after 3 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: "#FBD9D9",
              color: "#000", // Optional: Set text color for readability
            },
          });
          return true; // Exit the loop
        }
        if (field.name === "height") {
          const heightValue = parseFloat(formData[field.name]);
          if (isNaN(heightValue) || heightValue <= 0 || heightValue > 3) {
            isValid = false;
            toast.error(
              "Height must be a positive number and should not exceed 3 meters!",
              {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                  backgroundColor: "#FBD9D9",
                  color: "#000", // Optional: Set text color for readability
                },
              }
            );
            return true; // Exit the loop
          }
        }

        // Validation for Weight
        if (field.name === "weight") {
          const weightValue = parseInt(formData[field.name], 10);
          if (isNaN(weightValue) || weightValue <= 0 || weightValue > 999) {
            isValid = false;
            toast.error(
              "Weight must be a positive number and should not exceed 999 kg!",
              {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                  backgroundColor: "#FBD9D9",
                  color: "#000", // Optional: Set text color for readability
                },
              }
            );
            return true; // Exit the loop
          }
        }
        if (field.name === "age") {
          const ageValue = parseInt(formData[field.name], 10);

          // const ageValue = formData[field.name] ?.trim()? parseInt(formData[field.name].trim(), 10) : NaN;
          console.log("Parsed Age Value:", ageValue);
          console.log("Validating Age:", ageValue);
          if (isNaN(ageValue) || ageValue < 12) {
            isValid = false;
            toast.error(
              <>
                <b>Age Limit Alert</b>
                <br />
                Age below 12 is not eligible for this Ayurvedic test.
              </>,
              {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                  backgroundColor: "#FBD9D9",
                  color: "#000", // Optional: Set text color for readability
                },
              }
            );
            return true; // Exit the loop
          }
        }

        if (
          field.name === "contactno" &&
          !/^\d{10}$/.test(formData[field.name])
        ) {
          isValid = false;
          toast.error("Contact No. must be a 10-digit number!", {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: "#FBD9D9",
              color: "#000", // Optional: Set text color for readability
            },
          });
          return true; // Exit the loop
        }
        if (
          field.type === "radio" &&
          (!formData[field.name] || formData[field.name].length === 0)
        ) {
          isValid = false;

          toast.error(`${field.label} is mandatory!`, {
            autoClose: 3000, // Closes the toast after 3 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: "#FBD9D9",
              color: "#000", // Optional: Set text color for readability
            },
          });
          return true; // Exit the loop
        }
        // if (
        //   field.type === "checkbox" &&
        //   (!formData[field.name] || formData[field.name].length === 0)
        // ) {
        //   isValid = false;
        //   // alert(`${field.header} is mandatory!`);
        //   toast.error(`This question is mandatory!`, {
        //     // position: toast.POSITION.TOP_CENTER,
        //     autoClose: 3000, // Closes the toast after 3 seconds
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true, });
        //   return true; // Exit the loop
        // }
        if (field.type === "checkbox") {
          const groupedCheckboxFields = [
            "noninflamation1",
            "noninflamation1.2",
          ]; // Add all related checkbox field names here
          const isAnyChecked = groupedCheckboxFields.some(
            (groupName) => formData[groupName] && formData[groupName].length > 0
          );
          if (!isAnyChecked) {
            isValid = false;
            toast.error(`question is mandatory!`, {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              style: {
                backgroundColor: "#FBD9D9",
                color: "#000", // Optional: Set text color for readability
              },
            });
            return true; // Exit validation for this field group
          }
        }
      }

      return false; // Continue to the next field
    });

    if (isValid) {
      nextSet();
      console.log("updated ans", ans);
    }
  };

  const nextSet = () => {
    const totalSetsForCurrentStep = steps[currentStep - 1].sets;
    // setFormData({})

    if (currentSet < totalSetsForCurrentStep) {
      setCurrentSet(currentSet + 1);
    } else if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
      setCurrentSet(1);
    }
    setAnsAdded(false);
    // console.log("updated ans", ans);
  };

  const prevSet = () => {
    if (currentSet > 1) {
      setCurrentSet(currentSet - 1);
    } else if (currentStep > 1) {
      const previousStepSets = steps[currentStep - 2].sets;
      setCurrentStep(currentStep - 1);
      setCurrentSet(previousStepSets);
    }
  };

  const skipSet = () => {
    const totalSetsForCurrentStep = steps[currentStep - 1].sets;

    if (currentSet < totalSetsForCurrentStep) {
      setCurrentSet(currentSet + 1); // Skip to the next set
    } else if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1); // Skip to the next step
      setCurrentSet(1); // Reset to the first set of the next step
    }
    setAnsAdded(false);
  };

  const calculateProgressForStep = () => {
    const totalSetsForCurrentStep = steps[currentStep - 1].sets;
    return ((currentSet - 1) / totalSetsForCurrentStep) * 100;
  };

  const isStepCompleted = (step) =>
    step < currentStep ||
    (step === currentStep &&
      currentSet === totalSetsForCurrentStep &&
      setSubmitted);

  return (
    <div className="Test-main-Container">
      {/* {!enduser ? (<Header />) : null} */}
      <Header />
      <div className="Test-wrap">
        <div className="dynamic-multi-step-form">
          {/* Steps Header */}
          <div className="steps-container">
            {steps.map((step, index) => (
              <React.Fragment key={step.step}>
                <div
                  className={`step-container ${
                    index === steps.length - 1 ? "w-[8%]" : "w-[22.5%]"
                  } relative h-fit`}
                >
                  <div
                    className={`step ${
                      isStepCompleted(step.step)
                        ? "completed"
                        : currentStep === step.step
                        ? "active"
                        : "pending"
                    }`}
                  >
                    {step.step}
                  </div>
                  {/* Step Description for Desktop */}
                  <div className="step-description desktop-only">
                    {step.label1}
                    <br />
                    <div className="step-text">{step.label2}</div>
                    <div
                      className={`step-status ${
                        isStepCompleted(step.step)
                          ? "completed"
                          : currentStep === step.step
                          ? "active"
                          : "pending"
                      }`}
                    >
                      {isStepCompleted(step.step)
                        ? t("Completed")
                        : t("Pending")}
                    </div>
                  </div>

                  {index < steps.length - 1 && (
                    <div className="progress-indicator-container absolute hideen md:block">
                      <div
                        className={`h-[10px] w-[10px] rounded-full bg-white z-[30] absolute top-0 transition duration-300 ease-in-out`}
                        style={{
                          border: "2px solid #085946",
                          left:
                            currentStep === step.step
                              ? `${calculateProgressForStep() - 2}%`
                              : isStepCompleted(step.step)
                              ? "105%"
                              : "-5%",
                        }}
                      ></div>
                      <div
                        className="progress-indicator"
                        style={{
                          width:
                            currentStep === step.step
                              ? `${calculateProgressForStep()}%`
                              : isStepCompleted(step.step)
                              ? "100%"
                              : "0%",
                        }}
                      ></div>
                    </div>
                  )}

                  {/* <div className="step-description ">
                    {step.label1}
                    <br></br>
                    <div className="step-text">{step.label2}</div>
                    <div
                      className={`step-status ${
                        isStepCompleted(step.step)
                          ? "completed"
                          : currentStep === step.step
                          ? "active"
                          : "pending"
                      }`}
                    >
                      {isStepCompleted(step.step) ? "Completed" : "Pending"}
                    </div>
                  </div> */}
                </div>
              </React.Fragment>
            ))}
            {/* Mobile Continuous Progress Bar */}
          </div>
          <div className="progress-bar-mobile mt-4 md:hidden">
            <div
              className="progress-bar-fill"
              style={{
                width: `${(currentStep / steps.length) * 100}%`,
              }}
            ></div>
          </div>
          {/* Step Description for Mobile */}
          {/* {steps.map((step)=>
            {currentStep === step.step && !isStepCompleted(step.step) && (
                    <div className="step-description mobile-only">
                      {step.label1}
                      <br />
                      <div className="step-text">{step.label2}</div>
                      <div
                      className={`step-status ${
                        isStepCompleted(step.step)
                          ? "completed"
                          : currentStep === step.step
                          ? "active"
                          : "pending"
                      }`}
                    >
                      {isStepCompleted(step.step) ? "Completed" : "Pending"}
                    </div>
                    </div>
                  )}
                  )} */}
          {steps
            .filter((step) => step.step === currentStep) // Ensure only active step is rendered
            .map((step) => (
              <div
                className="step-description mobile-only"
                key={`mobile-desc-${step.step}`}
              >
                {step.label1}
                <br />
                <div className="step-text">{step.label2}</div>
                <div
                  className={`step-status ${
                    isStepCompleted(step.step)
                      ? "completed"
                      : currentStep === step.step
                      ? "active"
                      : "pending"
                  }`}
                >
                  {isStepCompleted(step.step) ? t("Completed") : t("Pending")}
                </div>
              </div>
            ))}

          {/* Question Content */}
          <div className="question-content">
            {/* Question Set */}
            {!setSubmitted && (
              <QuestionSet
                qIndex={currentSet - 1}
                setAns={setAns}
                questions={questionSets[currentStep]?.[currentSet - 1]} // Pass current question set
                formData={formData}
                handleInputChange={handleInputChange}
                skipSet={skipSet}
                setVideo={setVideo}
                ans={ans}
              />
            )}
            <div className="navigation-buttons rounded-b-lg">
              {/* <div className="w-full flex justify-end mx-10"> */}
              <div
                className={`w-full flex ${
                  currentStep === 1
                    ? "justify-center tabPotrait:justify-end"
                    : "justify-end"
                } mx-10`}
              >
                {currentStep > 1 && (
                  <button
                    className="hover:scale-[95%] transition-all duration-500 ease-in-out w-40 h-10 bg-transparent border-2 border-cbg2 text-cbg2 hover:bg-cbg2 hover:text-white"
                    onClick={prevSet}
                    disabled={currentStep === 1 && currentSet === 1}
                  >
                    {t("Previous")}
                  </button>
                )}
                {!setSubmitted ? (
                  <button
                    className="hover:scale-[95%] transition-all duration-500 ease-in-out w-40 h-10 bg-cbg2 text-white"
                    onClick={handleSubmitSet}
                  >
                    {questionSets[currentStep]?.[currentSet - 1].allowSkip &&
                    !isAnsAdded
                      ? t("Skip")
                      : t("Next")}
                  </button>
                ) : (
                  <button
                    className="hover:scale-[95%] transition-all duration-500 ease-in-out w-40 h-10 bg-cbg2 text-white"
                    onClick={nextSet}
                  >
                    {currentStep === steps.length &&
                    currentSet === totalSetsForCurrentStep
                      ? "Finish"
                      : "Next"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Test;
