import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import cam from "../../assets/cam.png";
import WebcamRecorder from "../../routes/Webcam/circ-webcam";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const email = localStorage.getItem("email");
const adminEmails = ["ad@b.c", "RashmiAdmin@123"];

if (adminEmails.includes(email)) {
  import("./QuestionsetAdmin.css");
} else {
  import("./Questionset.css");
}

const QuestionSet = ({
  questions,
  formData,
  setAns,
  qIndex,
  handleInputChange,
  skipSet,
  ans
}) => {
  const [timer, setTimer] = useState(10);
  const [screenshot, setScreenshot] = useState(null);
  const videoRef = useRef(null);
  const webcamRef = useRef(null);
  const webcamRefTab = useRef(null);
  const webcamRefMob = useRef(null);
  const [loader, setLoader] = useState(false);

  const [isRecording, setRecording] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isModelLoading, setModelLoading] = useState(false);
  const [huntToken, setHuntToken] = useState(false);
  const location = useLocation();
  // const [t, sett] = useState("");
  const [isAlertActive, setAlertActive] = useState(false);
  const [showsuccess, setShowSuccess] = useState(false);
  const [showfailure, setShowFailure] = useState(false);
  const { t, i18n } = useTranslation();
  
  if (!questions.fields || questions.fields.length === 0) {
    return <p>No questions available for this set.</p>;
  }

  const renderFields = (fields) => {
    return fields.map((field, index) => {
      if (field.type === "checkbox") {
        // Render checkbox group
        return (
          <div key={index} className="form-group-checkbox">
            <label className="checkbox-label">{field.label}</label>
            <div className="checkbox-group">
              {field.options.map((option, optIndex) => (
                <div key={optIndex} className="checkbox-option">
                  <input
                    type="checkbox"
                    id={`${field.name}-${optIndex}`}
                    name={field.name}
                    value={field.value[optIndex]}
                    checked={(formData[field.name] || []).includes(
                      String(field.value[optIndex])
                    )}
                    onChange={handleInputChange}
                    className="checkbox-input"
                  />
                  <label htmlFor={`${field.name}-${optIndex}`}>{option}</label>
                </div>
              ))}
            </div>
          </div>
        );
      } 
      else if (field.type === "text" || field.type === "select"||field.type === "number") {
        // Render text input field
        return (
          <div key={index} className="form-group-text">
            <label htmlFor={field.name} className="text-label">
              {field.label}
            
            </label>
            {field.type === "text" ? (
              <input
                type="text"
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
                className="text-input"
              />
            ) : field.type === "select" ?(
              <div style={{ position: "relative", width: "100%" }}>
              <select
                id={field.name}
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
                className="text-input"
                style={{
                  WebkitAppearance: "none", // CamelCase for -webkit-appearance
                  appearance: "none",       // Standard property for broader support
                  border: "1px solid #ccc", // Optional additional styling
                  padding: "8px",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  width: "100%", // Make it responsive
                  background: `white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23666'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E") no-repeat right 12px center`,
                  backgroundSize: "16px",
                   cursor: "pointer",
                }}
              >
                <option value="">{t("Selectanoption")}</option>
                {field.options.map((option, index) => (
                  <option key={index} value={field.value[index]}>
                    {option}
                  </option>
                ))}
              </select>
              </div>
            ):( <input
              type="number"
              id={field.name}
              name={field.name}
              placeholder={field.placeholder}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
              className="text-input"
              min="0"
              style={{
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                appearance: "textfield",
                border: "1px solid #ccc",
                padding: "8px",
                borderRadius: "4px",
                width: "100%",
              }}
            />
          )}
          </div>
        );
      }
      if (field.type === "radio") {
        // Render checkbox group
        return (
          <div key={index} className="form-group-radio">
            <label className="radio-label">
              {field.label}
              <span className="text-red-500">*</span>
            </label>
            <div className="radio-group">
              {field.options.map((option, optIndex) => (
                <div key={optIndex} className="radio-option">
                  <input
                    type="radio"
                    id={`${field.name}-${optIndex}`}
                    name={field.name}
                    value={optIndex + 1}
                    checked={formData[field.name] === String(optIndex + 1)}
                    onChange={handleInputChange}
                    className="radio-input"
                  />
                  <label htmlFor={`${field.name}-${optIndex}`}>{option}</label>
                </div>
              ))}
            </div>
          </div>
        );
      }

      return null; // Skip unsupported field types
    });
  };

  const getFormDataAsJSON = (e) => {
    const formDataJSON = {};

    // Loop through each field in the form
    questions.fields.forEach((field) => {
      if (field.type === "checkbox") {
        formDataJSON[field.name] = formData[field.name] || [];
      } else if (field.type === "radio") {
        formDataJSON[field.name] = formData[field.name] || [];
      } else if (field.type === "text") {
        formDataJSON[field.name] = formData[field.name] || "";
      }
    });
    
    setAns(val => ({...val, qIndex: formDataJSON}))
    
  };

  function alertWDC(message) {
    setAlertActive(true);
    alert(message);
    setTimeout(() => setAlertActive(false), 100);
  }

  // const handleStartRecording = async () => {
  //   const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //   if (stream) {
  //     setIsRecording(true);
  //     setTimer(10);
  //     videoRef.current?.scrollIntoView({ behavior: 'smooth' })
  //   } else {
  //     alert("Please provide camera permission");
  //   }
  // };

  const startRecording = (e) => {
    if (isRecording) return;
    if (!isChecked) {
      alertWDC("Please agree to the privacy policy");
      return;
    }
    // const formData = updateInput(e, alertWDC);
    if (!formData) return;
    setModelLoading(performance.now());
    // setJsonData(details);
    setRecording(true);
  };

  return (
    <>
      {questions?.last !== "yes" && (
        <div className="question-set rounded-t-lg">
          {/* Render header and description */}
          {questions.header && (
            <div className="header-section">
              <h3 className="question-header">{questions.header}</h3>
              {questions.description && (
                <p className="question-description">{questions.description}</p>
              )}
            </div>
          )}

          {/* Render fields */}
          <div className="questions-container">
            {renderFields(questions.fields)}
          </div>
        </div>
      )}
      {questions?.last === "yes" && (
        <div
          className="question-set w-full h-fit rounded-t-lg flex flex-col tabPotrait:flex-row justify-between items-center relative box-border tabPotrait:px-10"
          style={{
          }}
        >
          <div className="w-full tabPotrait:w-[33%] h-fit tabPotrait:h-80 text-start box-border flex flex-col justify-center items-start">
            <div className="text-xl font-semibold">{t("Capture60SecVideo")}</div>
            <div className="mt-5 text-base text-start tracking-tight">
              <b> {t("VideoNote").split(" - ")[0]}</b> 
               {t("VideoNote").split(" - ")[1]}
            </div>
            <button
              className="transition-all duration-300 hover:scale-[95%] w-32 tabPotrait:w-40 h-10 cursor-pointer py-[10px] mt-2 tabPotrait:mt-10 mb-10 tabPotrait:mb-0 text-white bg-cbg2 rounded-lg"
              onClick={startRecording}
              id="record-btn"
            >
             {t("TakeVideo")}
            </button>
          </div>
          <div className="w-full tabPotrait:w-[33%] h-fit flex flex-col justify-center items-center">
            <WebcamRecorder
              className=""
              startRecording={isRecording}
              setRecording={setRecording}
              setLoading={setModelLoading}
              token={t}
              isChecked={isChecked}
              isAlertActive={isAlertActive}
              setAlertActive={setAlertActive}
              setShowSuccess={setShowSuccess}
              setShowFailure={setShowFailure}
              ans={ans}
            />
          </div>
          <div className="w-full tabPotrait:w-[33%] h-[5vw] tabPotrait:h-80 flex flex-row justify-end items-end">
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionSet;
