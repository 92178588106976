import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./LearnMore.css";
import boardingImg from "../../assets/Learn-more-aurveda-pic.png";
import boardingImgMobile from "../../assets/Learn-more-aurveda-pic-mobile.png";
import { useTranslation } from "react-i18next";
function LearnMore() {
  const [isMobile, setMobile] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const { t, i18n } = useTranslation();

  const faqData = [
    {
      question: t("id1"),
      answer:
        t("ans1"),
    },
    {
      question: t("id2"),
      answer:
        t("ans2"),
    },
    {
      question: t("id3"),
      answer:
        t("ans3"),
    },
    {
      question: t("id4"),
      answer:
        t("ans4"),
    },
    {
      question: t("id5"),
      answer:
        t("ans5"),
    },
    {
      question: t("id6"),
      answer:
       t("ans6"),
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="learnMore-main-container">
      <Header />
      <div className="learnMore-boarding">
        {isMobile ? (
          <img src={boardingImgMobile} className="learnMore-boarding-img" />
        ) : (
          <img src={boardingImg} className="learnMore-boarding-img" />
        )}
        <div className="learnMore-boarding-text">
          {t("LearnMoreAivotayur")}
        </div>
      </div>

      <div className="learnMore-Info">
        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhatIsAivotayur")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("PlatformDescription")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            {t("WhatIsPrakriti")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("PrakritiDescription")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            {t("HowIsPrakritiDetermined")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("PrakritiDetermination")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhyIsPrakritiImportant")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("PrakritiImportance")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhatIsVikrutiInAyurveda")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("VikrutiDescription")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            {t("HowIsVikrutiIdentified")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("VikrutiIdentification")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhyIsVikrutiImportant")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("VikrutiImportance")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            {t("CharacteristicsOfVata")}
          </div>
          <div className="learnMore-info-box-answer">
          {t("VataPhysicalTraits")}
          </div>
          <div className="learnMore-info-box-answer">
          {t("VataMentalTraits")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("VataDigestiveTraits")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhatHappensWhenVataIsIncreasedOrDecreased")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("IncreasedVata")}
          </div>
          <div className="learnMore-info-box-answer">
          {t("DecreasedVata")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("HowCanVataBeBalanced")}
          </div>
          <div className="learnMore-info-box-answer">
          {t("BalancingVata")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
          {t("WhatIsPittaDosha")}
          </div>
          <div className="learnMore-info-box-answer">
         {t("PittaDescription")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            {t("CharacteristicsOfPitta")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("PittaPhysicalTraits")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("PittaMentalTraits")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("PittaDigestiveTraits")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhatHappensWhenPittaIsIncreasedOrDecreased")}
          </div>
          <div className="learnMore-info-box-answer"></div>
          <div className="learnMore-info-box-answer">
           {t("IncreasedPitta")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("DecreasedPitta")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("HowCanPittaBeBalanced")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("BalancePitta")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
          {t("WhatIsKaphaDosha")}
          </div>
          <div className="learnMore-info-box-answer">
            {t("KaphaDescription")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhatAreTheCharacteristicsOfKapha")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("KaphaPhysicalTraits")}
          </div>
          <div className="learnMore-info-box-answer">
          {t("KaphaMentalTraits")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("KaphaDigestiveTraits")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("WhatHappensWhenKaphaIsIncreasedOrDecreased")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("IncreasedKapha")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("DecreasedKapha")}
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
           {t("HowCanKaphaBeBalanced")}
          </div>
          <div className="learnMore-info-box-answer">
           {t("EatLightSpicyWarmFoods")}
          </div>
        </div>

        
      </div>
      <div className="FAQ-WRAP">
        <div className="FAQ-text">FAQs</div>
        <div className="faq-section">
          {faqData.map((item, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{item.question}</span>
                <span className="plus-icon">{openIndex === index ? '-' : '+'}</span>
              </div>
              {openIndex === index && (
                <div className="faq-answer">
                  <p style={{ marginLeft: "2vw" }}>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnMore;
