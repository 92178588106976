import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./About.css";
import boardingImg from "../../assets/Learn-more-aurveda-pic.png";
import YogaSitting from "../../assets/YogaSitting.png";
import backGroundVideo from "../../assets/AboutVIDEO.mp4";
import { useTranslation } from "react-i18next";
function About() {
   const { t, i18n } = useTranslation();
  return (
    <div className="About-main-Container">
      <Header />
      <div className="About-boarding">
        <img src={boardingImg} className="About-boarding-img" />
        <div className="head-text-wrap">
          <div className="About-boarding-text">{t("About Us")}</div>
          <div className="About-boarding-text2">
            {t("AyurvedaWisdom")}
          </div>
        </div>
      </div>
      <div className="About-description">
        <div className="About-description-box">
          <div className="Pic-text-wrap">
            <div className="Pic-Wrap">
              <img src={YogaSitting} className="YogaSitting" />
            </div>
            <div className="Text-wrap">
              <div className="About-text">
              {t("AivotayurDescription")}
              </div>
              <div className="About-text">
               {t("GoalDescription")}{" "}
              </div>
              <div className="About-text">
               {t("BlendingTraditionWithTechnology")}
              </div>
              <div className="About-text">
                {t("AIAndAyurvedaApproach")}{" "}
              </div>
              <div className="About-text">
               {t("TrustedPartnerDescription")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="About-video-mission">
        <video className="about-background-video" autoPlay muted loop playsInline>
          <source src={backGroundVideo} type="video/mp4" />
        </video>
        {/* <div className="bg-white w-[75%] rounded-lg text-center flexx flex-col justify-evenly items-center p-5 py-10">
            <div className="text-[#085946] font-bold text-2xl justify-self-center">Mission</div>
            <div className="italic font-light leading-8 w-[60%] justify-self-center" style={{ fontFamily: 'EB Garamond !important' }}>To integrate the ancient wisdom of Ayurveda with cutting-edge AI technology, empowering individuals to achieve holistic wellness through personalized insights and actionable recommendations.</div>
            <hr className="border border-gray-200 my-5"></hr>
            <div className="text-[#085946] font-bold text-2xl justify-self-center">Vision</div>
            <div className="italic font-light leading-8 w-[65%] justify-self-center" style={{ fontFamily: 'EB Garamond !important' }}>AIVOTayur aims to revolutionize health and wellness by making Ayurveda accessible and effective for modern lifestyles, fostering a harmonious balance between tradition and innovation for global well-being.</div>
        </div> */}
        <div className="Mission-Vision-Wrap">
          <div className="Mission-Wrap">
            <div className="Mission">{t("Mission")}</div>
            <div className="Mission-text">
            {t("MissionDescription")}
            </div>
          </div>
          <div className="Vision-Wrap">
            <div className="Vision">{t("Vision")}</div>
            <div className="Vision-text">
              {t("VisionDescription")}
            </div>
          </div>
        </div>
      </div>
      <div className="mobilemissionvission">
        <div className="mobhead">{t("Mission")}</div>
        <div className="mobtext">
        {t("MissionDescription")}
        </div>
        <div className="mobhead">{t("Vision")}</div>
        <div className="mobtext">
        {t("VisionDescription")}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
