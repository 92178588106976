import React from "react";
import "./Footer.css";
import Footerlogo from "../../assets/careVedic.png";
import Whatsapp from "../../assets/WhatsApp.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-section">
        <div className="footer-logo">
          <img src={Footerlogo} alt="Aivotayur Logo" className="Footerlogo" />
          <p>
            {t("Description")}
          </p>
        </div>
        <nav className="footer-nav">
          <ul>
            <a href="/dashboard"> {t("Home")}</a>
            <a href="/about">{t("About Us")}</a>
            <a href="/test"> {t("Test Page")}</a>
            <a href="/contactus"> {t("Contact Us")}</a>
            <a href="/learn"> {t("Learn More")}</a>
            <a href="/reports"> {t("Reports")}</a>
          </ul>
        </nav>
        <div className="footer-contact">
         
          <div className="newsletter">
            <div className="newsletter-text">SUBSCRIBE TO NEWSLETTER</div>
            <form>
              <input type="email" placeholder="Enter your email" required />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
