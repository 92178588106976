import React, { useState } from "react";
import "./ContactUs.css";
import Header from "../../components/Header/Header";
import Cbackground from "../../assets/Contactbackground.png";
import location from "../../assets/mi_location.svg";
import mail from "../../assets/fluent_mail-24-regular.svg";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
function ContactUs() {
 const { t, i18n } = useTranslation();
  return (
    <div className="Contactus-main-container">
      <Header />
      <div className="LF-WRAP">
        <div className="left-container">
          <img src={Cbackground} className="cimage" alt="Contact Background" />
        </div>
        <div className="right-container">
          <div className="form-container">
            <div className="rtext1">{t("KeepInTouch")}</div>
            <div className="rtext2">
              {t("PromptResponse")}
            </div>
            <form className="contact-form">
              <input type="text" name="name" placeholder={t("NamePlaceholder")} required />
              <input type="email" name="email" placeholder={t("EmailPlaceholder")} required />
              <input
                type="tel"
                name="phone"
                placeholder={t("PhonePlaceholder")}
                required
              />
              <textarea
                name="message"
                placeholder={t("MessagePlaceholder")}
                rows="4"
                required
              />
              <button type="submit">{t("SubmitButton")}</button>
            </form>
          </div>
        </div>
      </div>
      <div className="ADF-Wrap">
        <div className="Adrees-box">
          <div className="india">{t("IndiaHQ")}</div>
          <div className="itext">
            {t("ConnectWithUs")}
          </div>
          <div className="itext2">
           {t("BusinessCollaboration")}
          </div>
          <div className="irwrap">
            <img src={location} className="location" alt="Location Icon" />
            <div className="itext3">
              {t("OfficeAddress")}
            </div>
          </div>
          <div className="irwarp2">
            <img src={location} className="location" alt="Location Icon" />
            <div className="itext3">
             {t("ShopAddress")}
            </div>
          </div>
          <div className="irwarp3">
            <img src={mail} className="mail" alt="Mail Icon" />
            <div className="itext3">info@aivot.in</div>
          </div>
        </div>
      </div>
      {/* <div className="FAQ-WRAP">
        <div className="FAQ-text">FAQs</div>
        <div className="faq-section">
          {faqData.map((item, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{item.question}</span>
                <span className="plus-icon">{openIndex === index ? '-' : '+'}</span>
              </div>
              {openIndex === index && (
                <div className="faq-answer">
                  <p style={{ marginLeft: "2vw" }}>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default ContactUs;
