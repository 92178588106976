import React from "react";
import {
  PDFDownloadLink,
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import AivotBG from "../../assets/Aivotlogobackground.jpg";
import Logo from "../../assets/Care_Vedic.png";
import Noto from "../../assets/noto_sans_hindi_font.ttf"
import { useTranslation } from "react-i18next";

Font.register({
  family: "Hindi",
  format: "truetype",
  src: Noto,
});

// Define styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Devnagiri !important",
    // position:"relative",
    // flexDirection:"row",
  },
  backgroundImage: {
    position: "absolute",
    top: -270,
    left: -380,
    display: "block",
    width: "230%",
    height: "175%",
    opacity: 0.25, // Watermark effect
    zIndex: 1,
  },
  topLogo: {
    width: "100px",
    // height: "33px",
    margin: "20px 0",
  },
  bottomText: {
    fontFamily: 'Hindi',
    fontSize: "1.9vw",
    width: "95vw",
    textAlign: "center",
    marginBottom: "2vw",
    lineHeight: "2vw",
    marginTop: "auto",
    fontFamily: 'Hindi',
  },
  container: {
    display: "flex",
    padding: "0vw 6vw",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    position: "relative",
  },
  header: {
    fontFamily: 'Hindi',
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1vw",
    borderBottomColor: "#ccc",
  },
  Logo: {
    height: "50%",
  },
  userDetailsContainer: {
    width: "100%",
    justifyContent: "space-between",
  },
  userDetailsRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
  },
  labelText: {
    fontFamily: 'Hindi',
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    width: "20%",
    padding: "1vw 0",
    borderRight: "1px solid black",
    fontFamily: 'Hindi',
  },
  valueText: {
    fontSize: 10,
    textAlign: "center",
    width: "20%",
    padding: "1vw 0",
    borderRight: "1px solid black",
    fontFamily: 'Hindi',
  },
  IText: {
    fontFamily: 'Hindi',
    color: "#000",
    fontSize: "2.3vw",
    fontWeight: "black",
    // marginBottom:"1vw",
  },

  sectionHeader: {
    fontFamily: 'Hindi',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    fontWeight: "bold",
    marginVertical: "3vw",
    textAlign: "center",
    backgroundColor: "#FFF",
    // color: "#4B4B4B",
    padding: "1vw 0",
    width: "88vw",
    borderRadius: "15%",
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 10,
  },
  tableRowheading: {
    flexDirection: "row",
    fontSize: "1.75vw",
    backgroundColor: "#F3F1F1",
    borderBottom: "1px solid  #085946",
    marginBottom: "20px",
    padding: "0.5vw 3vw",
  },
  tableRowHRV:{
    flexDirection: "row",
    fontSize: "2.2vw",
    padding: "0.2vw 2.6vw",
    borderBottom: "1px solid #e3dcdc",
    width: "88vw",

  },
  tableRow: {
    flexDirection: "row",
    fontSize: "2.5vw",
    padding: "0.5vw 3vw",
    borderBottom: "1px solid #e3dcdc",
    width: "88vw",
  },
  tableRowDiet:{
    flexDirection: "row",
    fontSize: "2.5vw",
    padding: "2vw 3.5vw",
    borderBottom: "1px solid #e3dcdc",
    width: "88vw",
  },
  rowscontainer: {
    border: "2px solid #e3dcdc",
    borderTopWidth: "1px",
    backgroundColor: "rgba(255, 255, 255, 0.80)",
    borderBottom: "2px solid grey",
    borderRadius: "0.5vw",
  },
  tableCol1: {
    width: "25%",
    padding: "1vw",
    width: "40%",
    textAlign: "left",
    fontSize: "10px",
  },
  tableCol2: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    fontSize: "10px",
    textAlign: "center",
  },
  tableCol3: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    fontSize: "10px",
    textAlign: "center",
  },
  tableCol4: {
    width: "25%",
    padding: "1vw",
    width: "30%",
    fontSize: "10px",
    textAlign: "center",
  },
  tableCol1H: {
    width: "25%",
    padding: "1vw",
    width: "40%",
    textAlign: "left",
  },
  tableCol2H: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    textAlign: "center",
  },
  tableCol3H: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    textAlign: "center",
  },
  tableCol4H: {
    width: "25%",
    padding: "1vw",
    width: "30%",
    textAlign: "center",
  },
  itervetextheading: {
    fontFamily: 'Hindi',
    lineHeight: "3vw",
    fontSize: "2.5vw",
    textAlign: "left",
    color: "#000",
    fontWeight: "bold",
  },
  itervetext: {
    fontFamily: 'Hindi',
    lineHeight: "2.5vw",
    fontSize: "2vw",
    textAlign: "left",
    color: "#000",
    fontWeight: "600",
    width: "88vw",
  },
  itervetextContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  tableColWide1: {
    fontFamily: 'Hindi',
    width: "44vw",
    padding: "1vw 0",
    paddingLeft: "15vw",
    fontSize: "2vw",
    textAlign: "left",
    alignItems: "center",
  },
  tableColWide2: {
    fontFamily: 'Hindi',
    width: "44vw",
    padding: "1vw 0",
    fontSize: "2vw",
    textAlign: "center",
    alignItems: "center",
  },
  tableColWide1H: {
    fontFamily: 'Hindi',
    width: "44vw",
    fontSize: "2vw",
    padding: 5,
    textAlign: "center",
    marginBottom: "5px",
    borderBottom: "2px solid grey",
    backgroundColor: "#F3F1F1",
  },
  tableColWide2H: {
    fontFamily: 'Hindi',
    width: "44vw",
    fontSize: "2vw",
    padding: 5,
    marginBottom: "5px",
    borderBottom: "2px solid grey",
    backgroundColor: "#F3F1F1",
  },
  vatatext: {
    fontFamily: 'Hindi',
    fontSize: "2vw",
    textAlign: "left",
    color: "#000",
    lineHeight: "3.2vw",
    fontWeight: "600",
    width: "88vw",
  },
  vataHtext: {
    fontFamily: 'Hindi',
    fontSize: "2.8vw",
    lineHeight: "3.2vw",
    color: "#085946",
    fontWeight:"800",
  },

  contentcontainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0vw 5vw ",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  Logo1: {
    width: "92.561px",
    height: "33px",
    marginTop: "12px",
  },
  sectionHeadertext: {
    fontFamily: 'Hindi',
    fontSize: 15,
    marginVertical: 10,
    textAlign: "center",
    backgroundColor: "#FFF",
    width: "100%",
    color: "#4B4B4B",
  },
  tableColtext1: {
    fontFamily: 'Hindi',
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    padding: 5,
    fontSize: "12px",
    textAlign: "center",
    color: "#000",
  },
  tableColtext2: {
    fontFamily: 'Hindi',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    padding: 5,
    fontSize: "10px",
    textAlign: "left",
    color: "rgba(110, 111, 113, 0.91)",
  },
  text: {
    fontFamily: 'Hindi',
    color: "#085946",
    paddingTop: "10px",
    fontSize: "2.5vw",
  },
  text2: {
    fontFamily: 'Hindi',
    color: "#4B4B4B",
    paddingTop: "10px",
    fontSize: "2.5vw",
    marginBottom: "3.5vw",
  },
  Logo2: {
    width: "92.561px",
    height: "33px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  TCtext:{
    fontFamily: 'Hindi',
    color: "#000",
    fontSize: "2.5vw",
    paddingTop:"2vw",
    alignItems:"left",
    width:'100%',
    textDecoration:'underline',
  },
  Dtext: {
    fontFamily: 'Hindi',
    color: "#4B4B4B",
    fontSize: "2.5vw",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  Detox: {
    fontFamily: 'Hindi',
    color: "#000",
    fontSize: "2.3vw",
  },
  Dtext1: {
    fontFamily: 'Hindi',
    color: "#4B4B4B",
    fontSize: "1.8vw",
    // paddingTop: "5px",
    paddingBottom: "5px",
  },
  text3: {
    fontFamily: 'Hindi',
    color: "#085946",
    paddingTop: "15px",
    fontSize: "2.5vw",
    textAlign: "center",
    paddingBottom: "25px",
  },
  tableColtable1heading: {
    fontFamily: 'Hindi',
    width: "20%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "left",
  },
  tableColtable2heading: {
    fontFamily: 'Hindi',
    width: "23%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "center",
  },
  tableColtable3heading: {
    fontFamily: 'Hindi',
    width: "34%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "center",
  },
  tableColtable4heading: {
    fontFamily: 'Hindi',
    width: "23%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "center",
  },
  tableColfoodtext1: {
    fontFamily: 'Hindi',
    width: "20%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#000",
    borderRight: "1px solid #e3dcdc",
  },
  tableColfoodtext2: {
    fontFamily: 'Hindi',
    width: "23%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#4B4B4B",
    borderRight: "1px solid #e3dcdc",
  },
  tableColfoodtext3: {
    fontFamily: 'Hindi',
    width: "34%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#4B4B4B",
    borderRight: "1px solid #e3dcdc",
  },
  tableColfoodtext4: {
    fontFamily: 'Hindi',
    width: "23%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#4B4B4B",
  },
  tableColfoodtext1Last: {
    width: "23%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",
  },
  tableColfoodtext2Last: {
    width: "23%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",
  },
  tableColfoodtext3Last: {
    width: "31%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",
  },
  tableColfoodtext4Last: {
    width: "23%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",

    // borderRight: "1px solid black",
  },

  Lifestyle: {
    fontFamily: 'Hindi',
    paddingTop: "15px",
    paddingBottom: "25px",
    fontSize: "2.75vw",
    textAlign: "center",
  },
  info_section: {
    display: "flex",
    width: "88vw",
    padding: "25px 18px 24px 18px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "white",
    borderRadius: "4px",
    border: "2px solid #cfc4c4",
    borderTop: "0px solid #cfc4c4",
  },
  info_section_text_1: {
    fontFamily: 'Hindi',
    marginBottom: "2vw",
    color: "#4B4B4B",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "3vw",
  },
  info_section_text_h1: {
    fontFamily: 'Hindi',
    // marginTop: "13px",
    color: "#000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "35px",
    letterSpacing: "0.2px",
  },
  info_section_text_2: {
    fontFamily: 'Hindi',
    color: "#4B4B4B",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "35px",
  },
  info_section_text_h2: {
    fontFamily: 'Hindi',
    color: "#000",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "35px",
  },
});

const MyDocument1 = ({
  vitals,
  profileData,
  email,
  ayur,
  prakriti,
  dietChart,
  exercise,
}) => {
  const { t, i18n } = useTranslation();
  console.log(
    "from pdf",
    vitals,
    profileData,
    email,
    ayur,
    prakriti,
    dietChart,
    exercise
  );

  // const vitalsMap = [
  //   {
  //     desc: "Heart Rate",
  //     vital: vitals.heart_rate,
  //     unit: "bpm",
  //     ref: "60 - 100",
  //   },
  //   {
  //     desc: "Stroke Volume",
  //     vital: vitals.stroke_volume,
  //     unit: "ml",
  //     ref: "60 - 100",
  //   },
  //   {
  //     desc: "MAP",
  //     vital: vitals.mean_atrial_pressure,
  //     unit: "mmHg",
  //     ref: "70 - 100",
  //   },
  //   { desc: "SBP", vital: vitals.sbp, unit: "mmHg", ref: "90 - 120" },
  //   { desc: "DBP", vital: vitals.dbp, unit: "mmHg", ref: "60 - 80" },
  //   { desc: "RMSSD", vital: vitals.rmssd, unit: "ms", ref: "80 - 200" },
  //   { desc: "SDNN", vital: vitals.sdnn, unit: "L/min", ref: "80 - 200" },
  //   { desc: "IBI", vital: vitals.ibi, unit: "ms", ref: "600-1200" },
  //   { desc: "SD1", vital: vitals.sd1, unit: "ms", ref: "60 - 140" },
  //   { desc: "SD2", vital: vitals.sd2, unit: "ms", ref: "70 - 180" },
  //   {
  //     desc: "Fatty liver index",
  //     vital: vitals.fattyLiverIndex,
  //     unit: "-",
  //     ref: "<40",
  //   },
  //   {
  //     desc: "URIC ACID",
  //     vital: vitals.uricAcid,
  //     unit: "mg/dl",
  //     ref: "3.5-8.5",
  //   },
  //   { desc: "STRESS INDEX", vital: vitals.stress_index, unit: "-", ref: "<27" },
  //   {
  //     desc: "OXYGEN SATURATION",
  //     vital: vitals.oxygen_saturation,
  //     unit: "%",
  //     ref: "95-100",
  //   },
  // ];
  const dietch = [
    {item: t("Rasas (Taste)"),},
    {item:  t("Cereals (Grains)"),},
    {item: t("Pulses (Legumes)"),},
    {item: t("Vegetables"),},
    {item: t("Fruits"),},
  
  ];
  const dietch2 = [
    {item: t("Rasas (Taste)"),},
    {item:  t("Cereals (Grains)")},
    {item: t("Pulses (Legumes)"),},
    {item: t("Vegetables"),},
    {item: t("Fruits"),},
    {item: t("Spices"),},
    {item: t("Non-Veg"),},
    {item: t("Milk Products"),},
    {item: t("Oil Products"),},
    {item:  t("Nuts/Seeds"),},
  ];
  
  const pexercise2 = [
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
  ];
  const aexercise = [
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
  ];
  const aexercise2 = [
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
  ];
  const vitalsMap = [
    {
      desc: "Heart Rate",
      vital: "79",
      unit: "bpm",
      ref: "60 - 100",
    },
    {
      desc: "Stroke Volume",
      vital: "86.24",
      unit: "ml",
      ref: "60 - 100",
    },
    {
      desc: "MAP",
      vital: "101.67",
      unit: "mmHg",
      ref: "70 - 100",
    },
    { desc: "SBP", vital: "139", unit: "mmHg", ref: "90 - 120" },
    { desc: "DBP", vital: "83", unit: "mmHg", ref: "60 - 80" },
    { desc: "RMSSD", vital: "271.96", unit: "ms", ref: "80 - 200" },
    { desc: "SDNN", vital: "171.23", unit: "L/min", ref: "80 - 200" },
    { desc: "IBI", vital: "751.5", unit: "ms", ref: "600-1200" },
    { desc: "SD1", vital: "192.3", unit: "ms", ref: "60 - 140" },
    { desc: "SD2", vital: "147.17", unit: "ms", ref: "70 - 180" },
    {
      desc: "Fatty liver index",
      vital: "72.25",
      unit: "-",
      ref: "<40",
    },
    {
      desc: "URIC ACID",
      vital: "5.67",
      unit: "mg/dl",
      ref: "3.5-8.5",
    },
    { desc: "STRESS INDEX", vital: "31", unit: "-", ref: "<27" },
    {
      desc: "OXYGEN SATURATION",
      vital: "99",
      unit: "%",
      ref: "95-100",
    },
  ];

  return (
    <Document>
      <Page size="A4">
        <View style={{ position: "relative" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image src={Logo} style={styles.topLogo} />
            <View style={[styles.container, { height: "100%" }]}>
              <View style={styles.header}>
                <View style={styles.userDetailsContainer}>
                  {/* Row for Labels */}
                  <View
                    style={[
                      styles.userDetailsRow,
                      { borderTop: "1px solid black" },
                    ]}
                  >
                    <Text style={styles.labelText}>{t("NAME")}</Text>
                    <Text style={styles.labelText}>{t("AGE")}</Text>
                    <Text style={styles.labelText}>{t("CONTACT")}</Text>
                    <Text style={styles.labelText}>{t("HEIGHT")}</Text>
                    <Text style={styles.labelText}>{t("WEIGHT")}</Text>
                  </View>

                  {/* Row for Data */}
                  <View style={styles.userDetailsRow}>
                    <Text style={styles.valueText}>{profileData.name}</Text>
                    <Text style={styles.valueText}>{profileData.age}</Text>
                    <Text style={styles.valueText}>{email}</Text>
                    <Text style={styles.valueText}>{profileData.height}</Text>
                    <Text style={styles.valueText}>{profileData.weight}</Text>
                      {/* <Text style={styles.valueText}>Rameshwari Dwivedi</Text>
                    <Text style={styles.valueText}>59</Text>
                    <Text style={styles.valueText}></Text>
                    <Text style={styles.valueText}>1.6</Text>
                    <Text style={styles.valueText}>74</Text> */}
                  </View>
                </View>
              </View>
              {/* HRV Parameters Section */}
              <Text style={styles.sectionHeader}>HRV Parameters</Text>
              <View style={styles.table}>
                <View style={styles.tablecont}>
                  <View style={styles.tableRowheading}>
                    <Text style={styles.tableCol1H}>VITALS</Text>
                    <Text style={styles.tableCol2H}>VALUES</Text>
                    <Text style={styles.tableCol3H}>UNITS</Text>
                    <Text style={styles.tableCol4H}>REFERENCE RANGES</Text>
                  </View>
                </View>
                <View style={styles.rowscontainer}>
                  {vitalsMap.map((param, index) => (
                    <View style={styles.tableRowHRV} key={index}>
                      <Text style={styles.tableCol1}>{param.desc}</Text>
                      <Text style={styles.tableCol2}>{param.vital}</Text>
                      <Text style={styles.tableCol3}>{param.unit}</Text>
                      <Text style={styles.tableCol4}>{param.ref}</Text>
                    </View>
                  ))}
                </View>
              </View>
                <Text style={styles.itervetextheading}>{t( "ParameterDescription")}</Text>
                <Text style={styles.itervetext}>SDNN:{t("SDNNDesc")}</Text>
                <Text style={styles.itervetext}>RMSSD:{t("RMSSDDesc")}</Text>
                <Text style={styles.itervetext}>SD1:{t( "SD1desc")}</Text>
                <Text style={styles.itervetext}>SD2:{t("SD2desc")}</Text>
                <Text style={styles.itervetext}>IBI:{t("IBIdesc")}</Text>
                <Text style={styles.itervetext}>Stroke Volume(SV):{t("SVdesc")}</Text>
                <Text style={styles.itervetext}>{t("Metrics")}</Text>
              <Text style={styles.bottomText}>
                {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
                {"\n"}
                {"(01/08)"}
              </Text>
            </View>
          </View>
          <Image src={AivotBG} style={styles.backgroundImage} />
        </View>
      </Page>
      <Page size="A4">
        <View style={[styles.container, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo1} />

          <Text style={styles.sectionHeader}>
            {t("Ayurveda results based on Questionnaire & AI")}
          </Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, { padding: "0" }]}>
              <Text style={styles.tableColWide1H}>{t("Dosha")}</Text>
              <Text style={styles.tableColWide2H}></Text>
            </View>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableColWide1, { color: "#256D9D" }]}>
                  {t("Vata")}
                </Text>
                <Text style={styles.tableColWide2}>
                  {parseFloat(ayur["VataValue"].toFixed(2))} %
               
                 
                </Text>
              </View>{" "}
              <View style={styles.tableRow}>
                <Text style={[styles.tableColWide1, { color: "#DD472A" }]}>
                  {t("Pitta")}
                </Text>
                <Text style={styles.tableColWide2}>
                  {parseFloat(ayur["PitaValue"].toFixed(2))} %
               
                
                </Text>
              </View>{" "}
              <View style={styles.tableRow}>
                <Text style={[styles.tableColWide1, { color: "#085946" }]}>
                  {t("Kapha")}
                </Text>
                <Text style={styles.tableColWide2}>
                  {parseFloat(ayur["KaphaValue"].toFixed(2))} %
                 
                 
                </Text>
              </View>
            </View>
            <Text style={styles.sectionHeader}>
              {t("Prakriti & Vikruti category")}
            </Text>
            <View style={styles.table}>
              <View style={[styles.tableRow, { padding: "0" }]}>
                <Text style={[styles.tableColWide1H, { width: "34vw" }]}>
                  {t("Prakriti")}
                </Text>
                <Text
                  style={[
                    styles.tableColWide2H,
                    { textAlign: "center", width: "54vw" },
                  ]}
                >
                  {t("Vikruti")}
                </Text>
              </View>
              <View style={styles.rowscontainer}>
                <View style={styles.tableRow}>
                  <Text
                    style={[
                      styles.tableColWide1,
                      { width: "34vw", textAlign: "left", paddingLeft: "9vw" },
                    ]}
                  >
                    {prakriti}
                  
                    
                  </Text>
                  <Text style={[styles.tableColWide2, { width: "54vw" }]}>
                    {t("Vata")} {ayur['Vata']==='Balanced'? t("Balanced"):ayur['Vata']==='Increased'?t("Increased"):t("Decreased")}, {t("Pitta")} {ayur["Pitta"]==='Balanced'? t("Balanced"):ayur["Pitta"]==='Increased'?t("Increased"):t("Decreased")}, {t("Kapha")}{" "}
                  {ayur["Kapha"]==='Balanced'? t("Balanced"):ayur["Kapha"]==='Increased'?t("Increased"):t("Decreased")}
              
             
                  </Text>
                </View>
              </View>
            </View>
            {/* Footer */}
          </View>
          <Text style={styles.vatatext}>
            <Text style={styles.vataHtext}>{t("Vata")} -</Text>{t("Vata governs movement, creativity, and communication. When balanced, it promotes energy and adaptability. An imbalanced Vata may lead to restlessness and dryness. Nourish with warm, grounding foods and maintain a regular routine.")}
          </Text>
          <Text style={styles.vatatext}>
            <Text style={styles.vataHtext}>{t("Pitta")} -</Text>{t("Pitta controls metabolism, digestion, and transformation. Balanced Pitta fosters intelligence and determination. Imbalances can cause irritability or overheating. Cool and soothing foods help to balance Pitta, along with staying calm and relaxed.")}
          </Text>
          <Text style={styles.vatatext}>
            <Text style={styles.vataHtext}>{t("Kapha")} -</Text> {t("Kapha provides structure, stability, and strength. When in balance, it promotes calmness and endurance. An imbalance may cause sluggishness or heaviness. Light, warm foods and regular exercise help maintain Kapha balance.")}
          </Text>

          <Text style={[styles.bottomText, { marginTop: "43vw" }]}>
          {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
            {"\n"}
            {"(02/08)"}
          </Text>
        </View>
        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={[styles.Logo1, { marginBottom: "3vw" }]} />
          <Text style={styles.IText}>
          {t("Based on your Prakriti and Vikruti analysis, your body is currently in a state of inflammation (AMA). To help reduce the inflammation, It is recommended to follow this 11-day diet plan.")}{" "}
          </Text>
          <Text style={styles.sectionHeader}>
           {t("From Day 1 to Day 5: Liquids Diet Along With Detox Water")}
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Morning (8 AM)")}</Text>
                <Text style={styles.tableColtext2}>
                {t("Soak 30 grams of split green gram for 3 hours. Add 180 ml of water and boil until the green gram becomes soft. Mash it, then add a pinch of salt, cumin (jeera), and pepper powder. Stir well and drink.")}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Mid-Day (12 PM)")}</Text>
                <Text style={styles.tableColtext2}>
                {t("Take one cup of little millet (Kutki) and soak it. Add 6 cups of water and boil until the millet becomes soft. Mash it, then add a little salt, cumin (jeera), and pepper. Stir well and drink.")}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Evening (5 PM)")}</Text>
                <Text style={styles.tableColtext2}>
                {t("Take one cup of semolina (Rava) and fry it in ghee. Add six cups of water and a bit of jaggery, then stir well and drink.")}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Night (8 PM)")}</Text>
                <Text style={styles.tableColtext2}>
                {t("Take one cup of Sabu-dana (tapioca pearls) and add six cups of water. Boil it until it reaches a soupy consistency. Add salt and red chili according to taste, then boil it again before drinking.")}
                </Text>
              </View>
            
      
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>
                  {t("Alternative")}{"\n"}{t("(Mid-Day / Night)")}
                </Text>
                <Text style={styles.tableColtext2}>
                {t("Wheat Gruel (Dalia): Start by rinsing 1/2 cup of broken wheat and dry roasting it in a pan until it turns golden. In a separate pot, bring 5 cups of water to a boil, then add the roasted wheat and stir well. Let it simmer on low heat for 15-20 minutes, stirring occasionally until the wheat becomes soft and fully cooked. Once done, season with a pinch of salt and mix everything together. Serve the gruel.")}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>
                  {t("Detox water")}{"\n"}{t("(Drink in place of Tea and coffee)")}
                </Text>
                <Text style={styles.tableColtext2}>
                {t("To make this detox water, take three liters of water and add two pinches of ginger powder, six pinches of coriander powder, and two pinches of cumin (jeera) powder. Stir the mixture well and bring it to a boil. Let it simmer for 5 minutes, then remove from heat. After cooling slightly, store the detox water in a steel bottle and drink it throughout the day to stay refreshed and hydrated.")}
                </Text>
              </View>
              </View>
              </View>
            
         
          <Text style={styles.sectionHeader}>
            {t("For Day 6 to day 8: Semi liquid diet along with detox water")}
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Morning (8 AM)")}</Text>
                <Text style={styles.tableColtext2}>
                 {t("To make oil-free upma, dry roast semolina until golden. In a heated pot, sauté mustard seeds, cumin, curry leaves, green chilies, and onions until softened, then add vegetables. Pour 2 cups of water, add turmeric and salt, bring to a boil, and gradually stir in the roasted semolina. Simmer for 3-4 minutes, garnish with coriander and lemon juice, and serve hot.")}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Mid-Day (12 PM)")}</Text>
                <Text style={styles.tableColtext2}>
                {t("Rinse and soak 1/2 cup of rice and 1/2 cup of yellow moong dal for 10-15 minutes. In a pot or pressure cooker, boil 4 cups of water and add rice, dal, turmeric, salt, and optional cumin seeds. Add vegetables like carrots, peas, or beans if desired, and cook until soft. Adjust the consistency with water if needed and serve hot.")}
                </Text>
              </View>
            </View>
          </View>

          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
          {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
            {"\n"}
            {"(03/08)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <View style={styles.rowscontainer}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColtext1}>{t("Evening (5 PM)")}</Text>
              <Text style={styles.tableColtext2}>
              {t("Rinse 1 cup of rice and cook with 2 cups of water and salt for about 15 minutes. For the dal, rinse 1 cup of lentils and cook in 3 cups of water until soft. In a pan, sauté cumin seeds, onions, ginger-garlic paste, tomatoes, and green chilies, then add turmeric and salt. Combine with the cooked lentils, simmer for 5-10 minutes, garnish with coriander, and serve with rice.")}
              </Text>
            </View>
          </View>
          <Text style={styles.sectionHeader}>
            {t("For Day 9 to Day 11: Semi solid diet along with detox water")}
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Morning (8 AM)")}</Text>
                <Text style={styles.tableColtext2}>
                {t("To make oil-free upma, dry roast semolina until golden. In a heated pot, sauté mustard seeds, cumin, curry leaves, green chilies, and onions until softened, then add vegetables. Pour 2 cups of water, add turmeric and salt, bring to a boil, and gradually stir in the roasted semolina. Simmer for 3-4 minutes, garnish with coriander and lemon juice, and serve hot.")}
                </Text>
              </View>
          
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Mid-Day (12 PM)")}</Text>
                <Text style={styles.tableColtext2}>
                  {t("Roti and simple sabji (any vegetable except brinjal and potato)")}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>{t("Evening (5 PM)")}</Text>
                <Text style={styles.tableColtext2}>
                {t("Rinse 1 cup of rice and cook with 2 cups of water and salt for about 15 minutes. For the dal, rinse 1 cup of lentils and cook in 3 cups of water until soft. In a pan, sauté cumin seeds, onions, ginger-garlic paste, tomatoes, and green chilies, then add turmeric and salt. Combine with the cooked lentils, simmer for 5-10 minutes, garnish with coriander, and serve with rice.")}
                </Text>
              </View>
            </View>
          </View>
         
          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
          {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
            {"\n"}
            {"(04/08)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <Text style={styles.text}>{t("DIET RECOMMENDATIONS")}</Text>
          <Text style={styles.text2}>
            {t("You can follow this diet after the initial 11 days to continue supporting better health.")}
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRowDiet}>
                <Text style={styles.tableColtext1}>{t("Breakfast (8:30-9 am)")}</Text>
                {prakriti === "Pitta Kapha" || prakriti === "Pitta Vata" || prakriti=== "Kapha Pitta"? (
                  <Text style={styles.tableColtext2}>
                    {t("Poha, methi paratha, Bael ka chhilka, idli and sambhar,upma, paneer paratha")}
                  </Text>
                ) : (
                  <Text style={styles.tableColtext2}>
                    {t("Oats porridge, sprout salad, boiled eggs, fruit smoothie")}
                  </Text>
                )}
              </View>
              <View style={styles.tableRowDiet}>
                <Text style={styles.tableColtext1}>{t("Lunch (1-2 pm)")}</Text>
                {prakriti === "Pitta Kapha" || prakriti === "Pitta Vata" || prakriti=== "Kapha Pitta" ? (
                  <Text style={styles.tableColtext2}>
                    {t( "Jowar ki roti, Wheat flour ki roti, Nachni ki roti, Bhindi sabji, Chole sabji, Rajma sabji, Palak paneer, Beans, Mixed vegetable curry, Chicken, Fish")}{" "}
                  </Text>
                ) : (
                  <Text style={styles.tableColtext2}>
                    {t("Wheat roti, jowar roti, rice roti, beans, capsicum, cauliflower, soy chunks, Matki dal, fish")}{" "}
                  </Text>
                )}
              </View>
              <View style={styles.tableRowDiet}>
                <Text style={styles.tableColtext1}>{t("Dinner (7-7:30 pm)")}</Text>
                {prakriti === "Pitta Kapha" || prakriti === "Pitta Vata" || prakriti=== "Kapha Pitta" ? (
                  <Text style={styles.tableColtext2}>
                    {t("Millet Upma, Vegetable Khichdi, Daliya (Cracked Wheat), Broccoli Soup, Sprouts (Moong), Nachni Soup (Ragi Soup) with Vegetables")}
                  </Text>
                ) : (
                  <Text style={styles.tableColtext2}>
                    {t("Ragi ambali, cooked vegetables, beetroot-carrot tikkis, rice and dal, hummus and rice roti, rice toor dal")}   {" "}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <Text style={styles.TCtext}>{t("In place of Tea & Coffee take Detox Water")}</Text>
          <Text style={styles.Dtext}>
            <Text style={styles.Detox}>{t("Detox water:")}</Text> {t( "To make this detox water, take six liters of water and add two pinches of cinnamon, six pinches of coriander powder, and two pinches of cumin (jeera) powder. Stir the mixture well and bring it to a boil. Let it simmer for 5 minutes, then remove from heat. After cooling slightly, store the detox water in a steel bottle and drink it throughout the day to stay refreshed and hydrated.")}
          </Text>
         

          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
          {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
            {"\n"}
            {"(05/08)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <Text style={styles.text3}>
           {t("You can follow the food items listed below to support the above Prakriti diet.")}
          </Text>
          <View style={[styles.table, { padding: "0 0" }]}>
            <View
              style={[styles.rowscontainer, { padding: "0 0", margin: "0 0" }]}
            >
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: "#F3F1F1", width: "100%", padding: "0 0" },
                ]}
              >
                <Text style={styles.tableColtable1heading}>{t("FOOD ITEMS")}</Text>
                <Text style={styles.tableColtable2heading}>{t("EAT")}</Text>
                <Text style={styles.tableColtable3heading}>
                  {t("OCCASIONALLY")}{"\n"}{t("(MONTHLY EAT 3 OR 4 TIMES)")}
                </Text>
                <Text style={styles.tableColtable4heading}>{t("AVOID")}</Text>
              </View>
              {dietch.map((param, index) => (
                <View style={[styles.tableRow, { padding: "0 0" }]} key={index}>
                  <Text style={styles.tableColfoodtext1}>{param.item}</Text>
                  <Text style={styles.tableColfoodtext2}>
                    {dietChart.eat?.[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext3}>
                    {dietChart.occasionally?.[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext4}>
                    {dietChart.avoid?.[index]}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <Text style={[styles.Dtext1, { width: "88vw" }]}>
            <Text style={styles.Detox}>{t("Note: - The amount of food depends on your regular dietary intake.").split(" - ")[0]}</Text>
            {t("Note: - The amount of food depends on your regular dietary intake.").split(" - ")[1]}
          </Text>
         
          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
          {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
            {"\n"}
            {"(06/08)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
         
          <View style={[styles.table, { padding: "0 0" }]}>
            <View
              style={[styles.rowscontainer, { padding: "0 0", margin: "0 0" }]}
            >
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: "#F3F1F1", width: "100%", padding: "0 0" },
                ]}
              >
                <Text style={styles.tableColtable1heading}>{t("FOOD ITEMS")}</Text>
                <Text style={styles.tableColtable2heading}>{t("EAT")}</Text>
                <Text style={styles.tableColtable3heading}>
                  {t("OCCASIONALLY")}{"\n"}{t("(MONTHLY EAT 3 OR 4 TIMES)")}
                </Text>
                <Text style={styles.tableColtable4heading}>{t("AVOID")}</Text>
              </View>
              {dietch2.slice(5).map((param, index) => (
                <View style={[styles.tableRow, { padding: "0 0" }]} key={index}>
                  <Text style={styles.tableColfoodtext1}>{param.item}</Text>
                  <Text style={styles.tableColfoodtext2}>
                    {dietChart.eat?.slice(5)[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext3}>
                    {dietChart.occasionally?.slice(5)[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext4}>
                    {dietChart.avoid?.slice(5)[index]}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <Text style={[styles.Dtext1, { width: "88vw" }]}>
            <Text style={styles.Detox}>{t("Note: - The amount of food depends on your regular dietary intake.").split(" - ")[0]}</Text>
            {t("Note: - The amount of food depends on your regular dietary intake.").split(" - ")[1]}
          </Text>
         
          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
          {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
            {"\n"}
            {"(07/08)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <Text style={styles.Lifestyle}>
            {t("Suggested Physical Activities, Asanas and Pranayam for Balance and Wellness")}
          </Text>
          <View style={styles.info_section}>
            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                {t("Exercise Intensity")}{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.intensity}</Text>
            </Text>

            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                {t("Focus")}{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.focus}</Text>
            </Text>

            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                {t("Physical Caution")}{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.caution}</Text>
            </Text>

            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                {t("Meditation")}{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.meditation} </Text>
            </Text>
            {/* Start of second Section */}
            <Text style={styles.info_section_text_h1}>{t("Recommended Asanas")}</Text>
            {aexercise.map((param, index) => (
              <Text
                style={[styles.info_section_text_2]}
                key={`aexercise-${index}`}
              >
                {param.asanas[index]}
              </Text>
            ))}
            <Text style={styles.info_section_text_h1}>{t("Recommended Pranayamas")}</Text>
            {pexercise2.map((param, index) => (
              <Text style={styles.info_section_text_2} key={index}>
                {param.pranayama[index]}
              </Text>
            ))}

            {/* Last section  */}
            {/* <Text style={styles.info_section_text_h2}>
              Meditation: Practice 15-30 minutes of meditation.
            </Text> */}
          </View>

          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
          {t("NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.")}
            {"\n"}
            {"(08/08)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
    </Document>
  );
};

export default MyDocument1;
