import React, { Suspense } from 'react'
import { PDFViewer, Font, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import MyDocument1 from './ReactPdf/ReactPdf'
import MyDocument2 from './ReactPdf/ReactPdfNonI'
import { useLocation } from 'react-router-dom'
import Devnagiri from "../assets/devnagiri_new_font.ttf"
import Noto from "../assets/noto_sans_hindi_font.ttf"

Font.register({
  family: "Devnagiri",
  format: "truetype",
  src: Noto,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  text: {
    fontFamily: "Devnagiri",
    fontSize: 14,
  },
});

function Pdf() {
  const location = useLocation();
  const state = location.state;
  console.log("state", state)
  return (
    <div style={{
        height:"100vh",
        width:"100vw",
        margin:0,
    }}>
    <PDFViewer width="100%" height="100%">
      {/* <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <Text>English Text</Text>
            <Text style={styles.text}>bhasha kal: यह एक हिंदी वाक्य है।</Text>
          </View>
        </Page>
      </Document> */}
      <Suspense fallback={<div>Loading...</div>}>
      <MyDocument1
        vitals={state.vitals}
        profileData={state.profileData}
        email={state.email}
        ayur={state.ayurResult}
        prakriti={state.prakritiSpaced}
        dietChart={state.data}
        exercise={state.pakritiexercise}
      />
      </Suspense>
    </PDFViewer>
    </div>
  )
}

export default Pdf