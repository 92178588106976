import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./Header.css";
import Headerlogo from "../../assets/careVedic.png";
import profile from "../../assets/iconprofile.png";

function Header() {
  const [profileImage, setProfileImage] = useState("");
 
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [searchResults, setSearchResults] = useState([]);
  const email = localStorage.getItem("email");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
   // Use location for detecting the current path
  const location = useLocation();
  const { t, i18n } = useTranslation(); // Use location for detecting the current path

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setMenuOpen(false); // Close the menu after navigation
  };
 
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change language dynamically
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const User = await axios.get("https://aivotayur.com/api/loginDetails", {
          params: { email },
        });
        setProfileImage(User.data.LoginData.profileImage || "");
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [email]);

  return (
    <header className="fixed flex justify-center w-full z-[10] bg-white shadow-cs1 border border-[#eff0f6]">
      <div className="flex xxs:hidden tabPotrait:flex flex-row justify-between items-center w-full h-fit px-[2vw]">
        <div>
          <img src={Headerlogo} alt="Aivotayur Logo" className="Headerlogo" />
        </div>
        <nav className="nav w-[40vw] mr-4 pcs:mr-8 ml-0 pcs:ml-2 text-small gap-4 tab:gap-6 pcs:gap-8">
          <a href="/dashboard" className={`nav-link ${location.pathname === "/dashboard" ? "active-nav" : ""}`}>{t("Home")}</a>
          <a href="/about" className={`nav-link ${location.pathname === "/about" ? "active-nav" : ""}`}>{t("About Us")}</a>
          <a href="/test" className={`nav-link ${location.pathname === "/test" ? "active-nav" : ""}`}>{t("Test Page")}</a>
          <a href="/contactus" className={`nav-link ${location.pathname === "/contactus" ? "active-nav" : ""}`}>{t("Contact Us")}</a>
          <a href="/learn" className={`nav-link ${location.pathname === "/learn" ? "active-nav" : ""}`}>{t("Learn More")}</a>
          <a href="/reports" className={`nav-link ${location.pathname === "/reports" ? "active-nav" : ""}`}>{t("Reports")}</a>
        </nav>
        <input
          className="search-bar w-fit pcs:ml-8 py-1 pl-2 tab:pr-16"
          type="text"
          // placeholder="Search for anything..."
          placeholder={t("Search Placeholder")}
        />
        <select
          className="bg-cbg2 text-white py-2 pr-8 pl-2 rounded-md hover:bg-cbg2 language-selector"
          
          onChange={(e) => changeLanguage(e.target.value)}
          value={i18n.language}
        >
          <option value ="en">{t("Language")}:English</option>
          <option value="hi">{t("Language")}:हिंदी</option>
        </select>
        <div className="justify-end search-profile gap-5">
          <img
            src={profileImage || profile}
            alt="Profile Icon"
            className="profile-icon"
            onClick={() => (window.location.href = "/editprofile")}
          />
        </div>
      </div>
      <nav className="mnavbar">
        <div className="mleftSection">
          <button className="mhamburger" onClick={toggleMenu}>
            {isMenuOpen ? "✖" : "☰"}
          </button>
          {isMenuOpen && (
            <div className="mmenu">
              <button onClick={() => handleNavigation("/dashboard")} className={`nav-link ${location.pathname === "/dashboard" ? "active-nav" : ""}`}>
                {t("Home")}
              </button>
              <button onClick={() => handleNavigation("/about")} className={`nav-link ${location.pathname === "/about" ? "active-nav" : ""}`}>
                {t("About Us")}
              </button>
              <button onClick={() => handleNavigation("/test")} className={`nav-link ${location.pathname === "/test" ? "active-nav" : ""}`}>
               {t("Test Page")}
              </button>
              <button onClick={() => handleNavigation("/contactus")} className={`nav-link ${location.pathname === "/contactus" ? "active-nav" : ""}`}>
                {t("Contact Us")}
              </button>
              <button onClick={() => handleNavigation("/learn")} className={`nav-link ${location.pathname === "/learn" ? "active-nav" : ""}`}>
                 {t("Learn More")}
              </button>
              <button onClick={() => handleNavigation("/reports")} className={`nav-link ${location.pathname === "/reports" ? "active-nav" : ""}`}>
                 {t("Reports")}
              </button>
            </div>
          )}
        </div>
        <div className="mrightSection">
          <input type="text" className="msearchBar"  placeholder={t("Search Placeholder")}  />
          <select className="mlanguageButton"  onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
            <option value ="en">{t("Language")}:English</option>
            <option value="hi">{t("Language")}:हिंदी</option>
          </select>
        </div>
        <div className="mprofile">
          <img
            src={profileImage || profile}
            alt="Profile"
            className="mprofileImage"
            onClick={() => (window.location.href = "/editprofile")}
          />
        </div>
      </nav>
    </header>
  );
}

export default Header;
