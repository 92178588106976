import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import boardingImg from "../../assets/Learn-more-aurveda-pic.png";
import Filter from "../../assets/Funnel.svg";
import DownArrow from "../../assets/dropdown.svg";
import Download from "../../assets/Download.png";
import "./ReportsPage.css";
import { useNavigate } from "react-router-dom";
import CopyIcon from "../../assets/copy_icon.svg";
import { useTranslation } from "react-i18next";

function ReportsPage() {
  const [email, setEmail] = useState(null);
  const [resultData, setResultData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const emailAvailable = localStorage.getItem("email");
    setEmail(emailAvailable);
    if (emailAvailable) {
      console.log("fetching...");
      fetchResultDetails(emailAvailable);
    }
  }, []);
  const copyToClipboard = () => {
    const content = document.getElementById("contentToCopy").innerText;
    navigator.clipboard.writeText(content).then(
      () => {
        alert("Content copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  const toResult = async (params) => {
    navigate("/result", {
      state: {
        iterveResult: params.vitals || {},
        ayurResult: params.aivotayur,
        profileData: {
          name: params.fullName,
          age: params.age,
          weight: params.weight,
          height: params.height,
          gender: params.sex,
        },
        infla: params.inflamation,
        capturedImage: "",
        token: "",
      },
    });
  };

  const fetchResultDetails = async (userEmail) => {
    try {
      const emailAvailable = localStorage.getItem("email");
      const adminEmails = ["ad@b.c", "RashmiAdmin@123"];

      let response;
      if (adminEmails.includes(emailAvailable)) {
        console.log("admin---->");
        response = await fetch(
          `https://aivotayur.com/api/resultDetailsAdmin?email=${userEmail}`
        );
      } else {
        console.log("user---->");
        response = await fetch(
          `https://aivotayur.com/api/resultDetails?email=${userEmail}`
        );
      }

      if (!response.ok) {
        const errorResponse = await response.json();
        setError(errorResponse.message || "Failed to fetch data");
        setLoading(false);
        return;
      }
      const data = await response.json();
      console.log(data.resultData);
      setResultData(data.resultData);
      // console.log("res type", data.resultData, typeof(data.resultData))
      setLoading(false);
    } catch (err) {
      setError("An error occurred while fetching data");
      setLoading(false);
    }
  };

  const sortData = (type) => {
    console.log("sorting", type);

    // Create a new sorted array
    const sortedData = [...resultData]; // Make a copy to avoid directly mutating state

    switch (type) {
      case "a-z":
        sortedData.forEach(
          (item) => (item.lowerCaseName = item.fullName.toLowerCase())
        );
        sortedData.sort((a, b) =>
          a.lowerCaseName.localeCompare(b.lowerCaseName)
        );
        break;

      case "z-a":
        sortedData.forEach(
          (item) => (item.lowerCaseName = item.fullName.toLowerCase())
        );
        sortedData.sort((a, b) =>
          b.lowerCaseName.localeCompare(a.lowerCaseName)
        );
        break;

      case "oldest-first":
        sortedData.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        break;

      case "newest-first":
        sortedData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;

      default:
        console.log("Invalid sort type");
        return;
    }

    console.log("sorted", sortedData);

    // Update the state with the sorted array
    setResultData(sortedData);
  };

  const headers = [t("Report ID"), t("Name"), t("Date"), t("Time"), t("Action")];

  const tableData = [
    {
      id: "#ABC1234",
      name: "Ram Shah",
      date: "11th October, 2024",
      time: "4:56 PM",
      action: "Download",
    },
  ];

  return (
    <div className="Reports-page-container">
      <Header />
      <div className="Reports-page-boarding">
        <img src={boardingImg} className="Reports-boarding-img" />
        <div className="Reports-page-text">{t("reportsPage")}</div>
      </div>
      <div className="Reports-Wrap">
        <div className="Reports-page-text2">{t("reportsDescription")}</div>
        <div className="FDT-wrap">
          {/* <div className="Filter-wrap">
            <div className="Filter-text">{t("filters")}</div>
            <img src={Filter} alt="Funnel" className="Funnel" />
          </div>
          <div className="Date-wrap">
            <div className="Date-text"> {t("date")}</div>
            <img src={DownArrow} alt="Dropdown" className="Dropdown" />
          </div>
          <div className="Time-wrap">
            <div className="Time-text">{t("time")}</div>
            <img src={DownArrow} alt="Dropdown" className="Dropdown" />
          </div> */}
          <div className="Time-wrap relative">
            <select
              className="h-full w-full bg-transparent flex text-center pr-5 z-[5] focus:outline-none"
              style={{ WebkitAppearance: "none" }}
              onChange={(e) => sortData(e.target.value)} // Call sortData with the selected value
              defaultValue="" // Default placeholder value
            >
              <option value="" disabled>
                {t("Sort by")}
              </option>
              <option value="a-z">{t("Name")}: A-Z</option>
              <option value="z-a">{t("Name")}: Z-A</option>
              <option value="newest-first">{t("Newest First")}</option>
              <option value="oldest-first">{t("Oldest First")}</option>
            </select>
            <img
              src={DownArrow}
              alt="Dropdown"
              className="absolute top-1/2 left-[80%] -translate-y-1/2 -translate-x-1 z-[1]"
            />
          </div>
        </div>
        <div className="Report_wrap">
          <div className="table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {resultData.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      marginBottom: "1vw",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    className="rounded-b-lg"
                  >
                    <td>{row._id.slice(0, 10)}</td>
                    <td>{row.fullName}</td>
                    <td>
                      {new Date(row.createdAt).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </td>
                    <td>
                      {new Date(row.createdAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </td>
                    <td>
                      <div className="Download-wrap">
                        <div
                          className="download-text cursor-pointer hover:text-blue-700 hover:underline"
                          onClick={() => toResult(row)}
                        >
                          {t("view report")}{" "}👁
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mobile-view">
        {resultData.map((row, index) => (
          <div key={index} className="mobile-card">
            <div className="mobile-card-header">
              <div>{t("Report ID")}</div>
              <div className="id_icon" id="contentToCopy">
                {row._id.slice(0, 10)}
                <img
                  src={CopyIcon} // Replace with your copy icon if needed
                  className="copy-icon"
                  alt="Copy"
                  onClick={copyToClipboard}
                />
              </div>
            </div>
            <div className="mobile-body">
              {" "}
              <div className="mobile-card-body">
                <p>{row.fullName}</p>
                <p>
                  {new Date(row.createdAt).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </p>
                <p>
                  {new Date(row.createdAt).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
              <div className="mobile-card-footer">
                <button className="download-text" onClick={() => toResult(row)}>
                  {t("view report")}{" "}👁
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default ReportsPage;
