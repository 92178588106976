import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import HomeLeft from "../../assets/Homeleft.png";
import HomeRight from "../../assets/phytotherapy-products-arrangement-still-life.png";
import Yoga from "../../assets/yoga.png";
import Restart from "../../assets/restart.png";
import Camera from "../../assets/camera.png";
import Doshas from "../../assets/Ayurveda doshas pic.png";
import DR from "../../assets/Rashmi mam pic.png";
import Prof from "../../assets/Prof Bimay Roy.png";
import Dr from "../../assets/Sandeep Sir pic.png";
import Ceo from "../../assets/Alok Tiwari.png";
import Hr from "../../assets/Nidhi Tiwari.png";
import Md from "../../assets/Pradeep gupta.png";
import Cto from "../../assets/Sarmila roy.png";
import Footer from "../../components/Footer/Footer";
import HomeTop from "../../assets/home_mob_top.png";

import rs_mam from "../../assets/rashmi_mam.png";
import cto_mam from "../../assets/cto_mam.png";
import nidhi_mam from "../../assets/nidhi_mam.png";
import prof from "../../assets/prof_sir.png";
import ceo from "../../assets/alok_sir.png";
import md from "../../assets/md_sir.png";
import sandeep_sir from "../../assets/sandeep_sir.png";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [advb, setAdvb] = useState(1);
  const [ldb, setLdb] = useState(1);

  const aNext = () => {
    if (advb === 3) return;
    else {
      setAdvb(advb + 1);
    }
  };

  const aPrev = () => {
    if (advb === 1) return;
    else {
      setAdvb(advb - 1);
    }
  };

  const lNext = () => {
    if (ldb === 4) return;
    else {
      setLdb(ldb + 1);
    }
  };

  const lPrev = () => {
    if (ldb === 1) return;
    else {
      setLdb(ldb - 1);
    }
  };

  const displayedItems = 3;

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const handleMouseUp = () => setIsDragging(false);
    window.addEventListener("mouseup", handleMouseUp);

    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  const handleMouseUp = () => {
    setIsDragging(false);
    const container = scrollContainerRef.current;
    const scrollLeft = container.scrollLeft;
    const childWidth = container.scrollWidth / displayedItems;
    console.log("Scroll Left:", scrollLeft);
    console.log("Child Width:", childWidth);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current) return;
      const container = scrollContainerRef.current;
      const scrollLeft = container.scrollLeft;
      const childWidth = container.scrollWidth / displayedItems;

      const newIndex = Math.round(container.scrollLeft / childWidth);
      setCurrentIndex(newIndex);
    };

    const container = scrollContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [displayedItems]);

  const handleAssessmentStart = () => {
    navigate("/test"); // Replace "/test" with the actual path of your test page
  };

  const scrollToIndex = (index) => {
    if (!scrollContainerRef.current) return;
    const childWidth = scrollContainerRef.current.scrollWidth / displayedItems;
    scrollContainerRef.current.scrollTo({
      left: index * childWidth,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ boxSizing: "border-box" }}>
      {/* D E S K T O P */}
      <div className="Main-container xxs:hidden tabPotrait:flex flex-col">
        <Header />
        <div className="flex flex-row mt-[12vh]">
          <div className="Left-container">
            <img src={HomeLeft} alt="Image" className="HomeLeft-Image" />
          </div>
          <div className="Middle-container px-6">
            <div className="flex text-center text-3xl pcs:text-4xl font-bold">
              Aivotayur
            </div>
            <div className="flex text-center text-3xl pcs:text-4xl text-[#393434] font-[450] uppercase tab:mt-5 pcs:mt-12 pc:mt-20">
             {t("Title")}
            </div>
            <div className="flex text-center text-xl pcs:text-2xl mt-5 pcs:mt-10">
            {t("Subtitle")}
            </div>
            <button
              className="mt-5 tab:mt-8 pcs:mt-10 bg-cbg2 text-white px-12 py-3 rounded-md hover:scale-95 transition-all duration-300 ease-in-out"
              onClick={handleAssessmentStart}
            >
              {t("ButtonText")}
            </button>
          </div>
          <div className="Right-container">
            <img src={HomeRight} alt="Image" className="HomeRight-Image" />
          </div>
        </div>
        <div className="Aivotayur-why px-20">
          <div className="Aivotayur-why-text">{t("WhyAyurvedaAndAI")}</div>
          <div className="Aivotayur-why-text2">
            {t("WelcomeMessage")}
          </div>
        </div>
        <div className="VP-Wrap">
          <div className="Prakriti">
            <div className="Prakriti-text">{t("Prakriti")}</div>
            <div className="Prakriti-text2">
              {t("PrakritiDefinition")}
            </div>
            <button
              className="bg-cbg2 text-white py-2 px-20 mt-5 rounded-md  hover:scale-95 transition-all duration-300 ease-in-out"
              onClick={() => navigate("/learn")}
            >
              {" "}
              {t("LearnMore")}
            </button>
          </div>
          <div className="Vikruti">
            <div className="Vikruti-text">{t("Vikruti")}</div>
            <div className="Vikruti-text2">
             {t("VikrutiDefinition")}
            </div>
            <button
              className="bg-cbg2 text-white py-2 px-20 mt-5 rounded-md hover:scale-95 transition-all duration-300 ease-in-out"
              onClick={() => navigate("/learn")}
            >
              {" "}
              {t("LearnMore")}
            </button>
          </div>
        </div>
        <div className="Work-wrap">
          <div className="HowWork mt-8">{t("HowItWorks")}</div>
          <div className="Itworks-wrap">
            <div className="work-box">
              <img src={Yoga} className="yoga" />
              <div className="Text">{t("DiscoverYourBodyType")}</div>
              <div className="para text-xl">
                {t("BodyTypeDescription")}
              </div>
            </div>
            <div className="work-box">
              <img src={Restart} className="yoga" />
              <div className="Text">{t("AssessYourCurrentState")}</div>
              <div className="para text-xl">
                {" "}
               {t("VikrutiAssessmentDescription")}
              </div>
            </div>
            <div className="work-box">
              <img src={Camera} className="yoga" />
              <div className="Text">{t("CaptureYourHealthProfile")}</div>
              <div className="para text-xl">
                {t("FinalAssessmentDescription")}
              </div>
            </div>
          </div>
        </div>
        <div className="Ayurveda">
          <div className="Ayur-Benefits">
          {t("TransformativeBenefits")}
          </div>
          <div className="Ayur-box">
            <img src={Doshas} className="Doshaspic" />
            <div className="Doshastext-wrap">
              <div className="Doshastext">
                <span className="Doshastextbold">
                {t("PersonalizedRecommendations").split(" - ")[0]}{" "}
                </span>{" "}
                {t("PersonalizedRecommendations").split(" - ")[1]}
              </div>
              <div className="Doshastext">
                <span className="Doshastextbold">{t("BalanceBodyMind").split(" - ")[0]}</span>{" "}
                {t("BalanceBodyMind").split(" - ")[1]}
              </div>
              <div className="Doshastext">
                {" "}
                <span className="Doshastextbold">
                {t("TrackHealthProgress").split(" - ")[0]}
                </span>{" "}
                {t("TrackHealthProgress").split(" - ")[1]}
              </div>
              <div className="Doshastext">
                <span className="Doshastextbold">
                {t("SafeNaturalProven").split(" - ")[0]}
                </span>{" "}
                {t("SafeNaturalProven").split(" - ")[1]}
              </div>
            </div>
          </div>
        </div>
        <div className="Advisory-Board">
          <div className="Advisory-text">{t("AdvisoryBoard")}</div>
          <div className="Advisory-text2 px-20 pb-5">
            {t("AdvisoryBoardDescription")}
          </div>
          <div className="Adviory-photo-box">
            <div className="Photo-box1">
              <img src={Prof} className="photo1 rounded-xl" />
              <div className="caption">
                Prof. Bimal Roy<br></br>Chief Strategic Advisor
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="advisory-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                {t("CryptologistDescription")}
                </p>
              </div>
            </div>
            <div className="Photo-box1">
              <img src={Dr} className="photo1 rounded-xl" />
              <div className="caption">
                Dr Sandeep Jyot<br></br>Chief Clinical Advisor
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="advisory-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  {t("MedicalExpertDescription")}
                </p>
              </div>
            </div>
            <div className="Photo-box1">
              <img src={DR} className="photo1dr rounded-xl" />
              <div className="caption">
              Dr. Rashmi Manjunatha Nookala<br></br>Subject Matter Expert
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="advisory-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                {t("DrRashmiDescription")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Leadership-Board">
          <div className="Leadership-text">{t("LeadershipTeam")}</div>
          <div className="Leadership-text2 px-20 pb-5">
          {t("LeadershipTeamDescription")}
          </div>
          <div className="Leadership-photo-box">
            <div className="Photo-box2">
              <img src={Md} className="photo2 rounded-xl" />
              <div className="caption">
                Pradeep Gupta<br></br>Managing Director
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                 {t("ProfessionalDescription")}
                </p>
              </div>
            </div>
            <div className="Photo-box2">
              <img src={Ceo} className="photo2 rounded-xl" />
              <div className="caption">
                Alok Tiwari<br></br>Founder & CEO
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                {t("AlokKumarTiwariDescription")}
                </p>
              </div>
            </div>
            <div className="Photo-box2">
              <img src={Cto} className="photo2  rounded-xl" />
              <div className="caption">
                Sarmila Roy<br></br>CTO
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                 {t("ProfessionalExperienceDescription")}
                </p>
              </div>
            </div>
            <div className="Photo-box2">
              <img src={Hr} className="photo2hr  rounded-xl" />
              <div className="caption">
                Nidhi Tiwari<br></br>Director- HR /<br></br>Fund Manager
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                {t("MrsNidhiDescription")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Assesment">
          <div className="Assesment-text1">
           {t("FirstStepDescription")}
          </div>
          <div
            className="w-fit mt-5 mb-10 tab:mt-8 pcs:mt-10 bg-cbg2 text-white px-12 py-3 rounded-md text-xl cursor-pointer hover:scale-95 transition-all duration-300 ease-in-out"
            onClick={handleAssessmentStart}
          >
            {t("StartAssessmentButton")}
          </div>
        </div>
        <Footer />
      </div>

      {/* M O B I L E */}
      <div className="w-full flex tabPotrait:hidden flex-col items-center  border-b border-black">
        <Header />
        <img src={HomeTop} className="w-full" />
        <div className="w-full flex flex-col items-center">
          <div className="p-3 flex flex-col items-center gap-3">
            <div className="text-[1.625rem] font-bold">Aivotayur</div>
            <div className="text-center text-xl text-tbg1 font-semibold px-1">
            {t("Title")}
            </div>
            <div className="text-center px-3">
             {t("Subtitle")}
            </div>
            <div className="px-2 w-full">
              <button
                className="w-full py-5 text-white rounded-lg bg-cbg2"
                onClick={handleAssessmentStart}
              >
                  {t("ButtonText")}
              </button>
            </div>
            <div className="text-center text-2xl font-semibold">
              {t("WhyAyurvedaAndAI")}
            </div>
            <div className="text-center px-2">
            {t("WelcomeMessage")}
            </div>
          </div>
          <div className="mt-3 w-full flex flex-col items-center">
            <div className="bg-cbg3 gap-6 py-5 px-10 flex flex-col items-center text-center">
              <div className="text-2xl">{t("Prakriti")}</div>
              <div className="leading-7">
              {t("PrakritiDefinition")}
              </div>
              <div
                className="w-full text-white"
                onClick={() => navigate("/learn")}
              >
                <button className="w-64 py-3 bg-cbg2 rounded-md shadow-cs1">
                 {t("LearnMore")}
                </button>
              </div>
            </div>
            <div className="bg-cbg4 gap-6 py-5 px-10 flex flex-col items-center text-center">
              <div className="text-2xl">{t("Vikruti")}</div>
              <div className="leading-7">
              {t("VikrutiDefinition")}
              </div>
              <div
                className="w-full text-white"
                onClick={() => navigate("/learn")}
              >
                <button className="w-64 py-3 bg-cbg2 rounded-md shadow-cs1">
                {t("LearnMore")}
                </button>
              </div>
            </div>
          </div>
          <div className="py-5 flex flex-col items-center gap-2">
            <div className="text-center text-2xl font-semibold">
            {t("HowItWorks")}
            </div>
            <div
              className="w-full h-fit p-[20px] gap-[5px] flex flex-row overflow-x-auto snap-x snap-mandatory select-none scrollContainer"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              ref={scrollContainerRef}
              style={{
                cursor: isDragging ? "grabbing" : "grab",
                scrollBehavior: "smooth",
              }}
            >
              <div className="w-full py-4 gap-3 flex flex-col justify-start rounded-xl shadow-cs2 flex-shrink-0 snap-center box-border">
                <img src={Yoga} className="w-10 ml-3" />
                <div className="text-xl font-medium ml-3">
                {t("DiscoverYourBodyType")}
                </div>
                <div className="text-tbg2 text-lg leading-6 ml-3">
                {t("BodyTypeDescription")}
                </div>
              </div>
              <div className="w-full py-4 gap-3 flex flex-col justify-start rounded-xl shadow-cs2 flex-shrink-0 snap-center box-border">
                <img src={Restart} className="w-10 ml-3" />
                <div className="text-xl font-medium ml-3">
                  {t("AssessYourCurrentState")}
                </div>
                <div className="text-tbg2 text-lg leading-6 ml-3">
                {t("VikrutiAssessmentDescription")}
                </div>
              </div>
              <div className="w-full py-4 gap-3 flex flex-col justify-start rounded-xl shadow-cs2 flex-shrink-0 snap-center box-border">
                <img src={Camera} className="w-10 ml-3" />
                <div className="text-xl font-medium ml-3">
                  {t("CaptureYourHealthProfile")}
                </div>
                <div className="text-tbg2 text-lg leading-6 ml-3">
                {t("FinalAssessmentDescription")}
                </div>
              </div>
            </div>

            {/* Dots Indicator with onClick event */}
            <div className="flex justify-center items-center gap-2 cursor-pointer">
              {Array(displayedItems)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full ${
                      currentIndex === index ? "bg-cbg2" : "bg-gray-400"
                    }`}
                    onClick={() => scrollToIndex(index)} // Add onClick to each dot
                  />
                ))}
            </div>
          </div>
          <div className="p-8 pb-0 flex flex-col items-center gap-3">
            <div className="text-center text-2xl font-semibold">
             {t("TransformativeBenefits")}
            </div>
            <div className="py-8 px-5 gap-3 flex flex-col justify-start rounded-xl shadow-cs3">
              <img src={Doshas} className="" />
              <div className="text-justify">
                <b>{t("PersonalizedRecommendations").split(" - ")[0]}</b>
                {t("PersonalizedRecommendations").split(" - ")[1]}
                <br />
                <br />
                <b>{t("BalanceBodyMind").split(" - ")[0]}</b>
                 {t("BalanceBodyMind").split(" - ")[1]}
                <br />
                <br />
                <b>{t("TrackHealthProgress").split(" - ")[0]}</b>
                 {t("TrackHealthProgress").split(" - ")[1]}
                <br />
                <br />
                <b> {t("SafeNaturalProven").split(" - ")[0]}</b> 
                 {t("SafeNaturalProven").split(" - ")[1]}
                <br />
                <br />
              </div>
            </div>
            <div className="mt-10 mb-5 text-2xl">{t("AdvisoryBoard")}</div>
          </div>
          <div>
            <div className="text-cbg2 mx-7 text-center rounded-3xl mb-5">
            {t("AdvisoryBoardDescription")}
            </div>
            {advb === 1 ? (
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${prof})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">
                        Prof. Bimal Roy
                      </div>
                      <div className="text-base font-medium">
                        Chief Strategic Advisor
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                      in
                    </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                {t("CryptologistDescription")}
                </div>
              </div>
            ) : null}
            {advb === 2 ? (
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${sandeep_sir})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">
                        Dr Sandeep Jyot
                      </div>
                      <div className="text-base font-medium">
                        Chief Clinical Advisor
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                      in
                    </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                {t("MedicalExpertDescription")}
                </div>
              </div>
            ) : null}
            {advb === 3 ? (
              
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${rs_mam})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">
                        Dr. Rashmi Manjunatha Nookala
                      </div>
                      <div className="text-base font-medium">
                        Subject Matter Expert
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                    in
                  </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                 {t("DrRashmiDescription")}
                </div>
              </div>
            ) : null}
            <div className="flex flex-row justify-between mx-8">
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => aPrev()}
              >
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {"<"}
                </div>
                Prev
              </div>
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => aNext()}
              >
                Next
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {">"}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full text-center mt-10 mb-5 text-2xl">
            {t("LeadershipTeam")}
            </div>
            <div className="text-cbg2 mx-7 text-center rounded-3xl mb-5">
            {t("LeadershipTeamDescription")}
            </div>
            {ldb === 1 ? (
             
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${md})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Pradeep Gupta</div>
                      <div className="text-base font-medium">
                        Managing Director
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                  in
                </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                {t("ProfessionalDescription")}
                </div>
              </div>
            ) : null}
            {ldb === 2 ? (
           
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#256D9D] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${ceo})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Alok Tiwari</div>
                      <div className="text-base font-medium">Founder & CEO</div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                in
              </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                 {t("AlokKumarTiwariDescription")}
                </div>
              </div>
            ) : null}
            {ldb === 3 ? (
             
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${cto_mam})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Sarmila Roy</div>
                      <div className="text-base font-medium">CTO</div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
              in
            </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                 {t("ProfessionalExperienceDescription")}
                </div>
              </div>
            ) : null}
            {ldb === 4 ? (
            
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${nidhi_mam})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Nidhi Tiwari</div>
                      <div className="text-base font-medium">
                        Director- HR /<br />
                        Fund Manager
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
            in
          </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  {t("MrsNidhiDescription")}
                </div>
              </div>
            ) : null}
            <div className="flex flex-row justify-between mx-8">
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => lPrev()}
              >
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {"<"}
                </div>
                Prev
              </div>
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => lNext()}
              >
                Next
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {">"}
                </div>
              </div>
            </div>
          </div>
          <div className="mx-5 mt-12 mb-5  text-2xl font-medium text-center">
           {t("FirstStepDescription")}
          </div>
          <div className="w-full flex items-center justify-center">
            <button className="w-full mx-16 mb-20 p-3 text-xl rounded-lg bg-cbg2 text-white"   onClick={handleAssessmentStart}>
             {t("StartAssessmentButton")}
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Dashboard;
